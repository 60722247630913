import { DownloadOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";

const FileDisplayer = ({ files, onClickDownload = undefined }) => {
  if (!files) return;
  if (!Array.isArray(files)) files = [files];

  if(!files.length) return (<div><b>Files: </b>No files added</div>)
  const handleDownload = (file) => {
    if (typeof onClickDownload === "function") return onClickDownload(file);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.click();
  };

  return (
    <div style={{ maxWidth: "300px" }}>
      <Typography.Title level={4} style={{ margin: "10px 0", lineHeight: 1, marginTop: 30 }}>📄 Files</Typography.Title>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {files.map((file, index) => (
          <li
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "8px",
              padding: "8px",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "background-color 0.2s",
            }}
            onClick={() => handleDownload(file)}
            title={typeof file === "string" ? file : file.name}
          >
            <span
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "royalblue",
                textDecoration: "underline",
              }}
            >
              {typeof file === "string" ? file : file.name}
              <span style={{ marginLeft: "8px", fontSize: "16px", color: "#1890ff" }}>
                <DownloadOutlined />
              </span>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileDisplayer;
