import { UploadOutlined } from "@ant-design/icons";
import { Button, message, notification, Popconfirm, Steps, theme, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Loading from "../../components/loading";
import antdPrompt from "../../components/prompts/antdPrompt";
import SelectForm from "../../components/select/selectform";
import { bulkUpload } from "../../firebase/data/create";
import { fetchResults } from "../../firebase/data/get";
import { catcher, except } from "../../firebase/util";
import { findCompanies, firstCompany, firstSite } from "../../helper/attribute";
import { getCurrentDate, getCurrentTime } from "../../helper/time";
import { selectFactors, setCompanies } from "../../redux/features/appSlice";
import { findForm } from "../../static/formRoutes";
import { routePath } from "../../static/routes.static";
import InputDataPage from "./inputdata";
import ReviewPage from "./reviewpage";
import { createFile } from "../../firebase/storage/create";
import { updateCompany } from "../../firebase/company/update";
import store from "../../redux/store";
import { generateRandomString } from "../../helper/wodash";
import Settings from "./settings";
import useSize from "../../hooks/useSize";
import IntermediateChecks from "./intermediate";

export default function BulkUploadForm() {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(null); // required: name
  const { isMobile } = useSize();
  /*Data */
  const factors = useSelector(selectFactors);
  const [data, setData] = useState({});
  const [isBlocking, setIsBlocking] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [settings, setSettings] = useState({
    type: "Default",
    sheetName: "Inputdata",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFileSaveToCompany = (id) => {
    catcher(
      async () => {
        const company = findCompanies()?.[0];
        const id = company?.id;
        if (!company) return message.error("Company not found");
        const filename = id + "-" + data.file.name;
        const downloadURL = await createFile("companydata/" + id + "/" + filename, data.file);
        const prevFiles = company.files || [];

        const updatedData = {
          files: [
            ...prevFiles,
            {
              name: filename,
              desc: "Bulk upload file added for " + data.name + " on " + getCurrentDate(),
            },
          ],
        };
        await updateCompany(id, updatedData);
        dispatch(setCompanies(store.getState().app.companies.map((company) => (company.id === id ? { ...company, ...updatedData } : company))));

        message.success("File uploaded successfully");
      },
      { setLoading: () => {}, success_msg: "" }
    );
  };
  const handleSubmit = () => {
    if (data?.zeroEmissionsError) {
      return message.error("Results are calculated to be zero, please check if you've selected the right file and format.");
    }
    handleExport();
    const unique_id = generateRandomString(6);
    handleFileSaveToCompany(unique_id);
    const rows = data.rows.filter((row) => !row?.err);
    except(async () => {
      if (errorRows().length) return message.error("Please correct all rows before uploading");
      if (!rows) {
        throw "File is empty.";
      }
      await bulkUpload(
        rows.map((row) => ({
          ...row,
          name: data.name,
          createdAt: getCurrentDate(),
          bulk_id: unique_id,
        }))
      );
      fetchResults(data.name);
      setSubmitted(true);
    });
  };
  useEffect(() => {
    if (submitted) navigate(routePath.Result(data.name));
  }, [submitted]);

  const errorRows = () => {
    let count = 0;
    if (!data.rows) return undefined;
    data.rows?.forEach((row) => (row.err || row.notFoundError) && count++);
    return count;
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQueryParams(Object.fromEntries(searchParams.entries()));
  }, [location.search]);
  useEffect(() => {
    if (queryParams) {
      setData({
        name: queryParams.name,
        siteId: firstSite().id,
      });
      setCurrent(1);
    }
  }, [queryParams]);
  // Block from going back
  useBlocker(
    (tx) => {
      if (data.rows?.length && isBlocking) {
        tx.block(antdPrompt("You haven't uploaded yet. Are you sure you want to leave?", "Yes", "", () => tx.retry(), -1));
      } else {
        tx.retry();
      }
    },
    [data.rows?.length, !submitted]
  );

  const steps = [
    {
      title: "Select Form",
      content: <SelectForm data={data} setData={setData} type="bulk-upload" />,
    },
    {
      title: "Upload Sheet",
      content: <InputDataPage setSettings={setSettings} settings={settings} data={data} setData={setData} />,
    },
    // {
    //   title: "Configuration",
    //   content: (
    //     <Settings
    //       setSettings={setSettings}
    //       settings={settings}
    //       data={data}
    //       setData={setData}
    //     />
    //   ),
    // },
    {
      title: "Edit data inputs",
      content: (
        <>
          <IntermediateChecks form={findForm(data.name)} settings={settings} data={data} setData={setData} handleSubmit={handleSubmit} errorRows={errorRows} />
        </>
      ),
    },
    // {
    //   title: "Review Page",
    //   content: (
    //     <>
    //       {/* <div style={{ display: "none" }}>
    //         <Settings setSettings={setSettings} settings={settings} data={data} setData={setData} />
    //         </div> */}
    //     </>
    //   ),
    // },
  ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = async () => {
    if (current == 1 && !data.file) {
      message.error("Please add in a file");
    } else {
      if (current === 1) {
        setCurrent(current + 1);
      } else {
        setCurrent(current + 1);
      }
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    minHeight: "40vh",
    padding: "10px",
    backgroundColor: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const handleExport = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    const rows = data.excelRows.filter((_, index) => data.rows[index].err);
    if (rows.length === 0) return;
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(rows);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a buffer
    const buffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${firstCompany()?.title}-${data.name} Error Rows.xlsx`;

    // Programmatically trigger the download
    link.click();

    // Clean up the temporary anchor element
    URL.revokeObjectURL(link.href);
    notification.open({
      type: "info",
      message: "Error Rows Downloaded",
      description: "Rows with error have been downloaded. Please ammend the errors and reupload!",
    });
  };
  if (!factors) return <Loading title={"Loading Emission Factors"} />;
  return (
    <>
      <Typography.Title level={4}>Bulk Upload Data</Typography.Title>
      <Steps current={current} items={items} />
      {current != 0 && (
        <div>
          <img
            style={{
              position: "absolute",
              right: 0,
              maxWidth: "250px",
              bottom: -0,
              display: isMobile ? "none" : "",
            }}
            src={findForm(data.name)?.bg}
          ></img>
        </div>
      )}

      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Popconfirm disabled={data?.rows?.filter((row) => !row.err)?.length === 0} title={`Confirm Upload ${data.rows?.filter((row) => !row.err).length} entries`} onConfirm={handleSubmit}>
            <Button type="primary" disabled={data?.rows?.filter((row) => !row.err)?.length === 0}>
              Upload
              <UploadOutlined />
            </Button>
          </Popconfirm>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
    </>
  );
}
