import { atom } from "jotai";

export const consultantAtom = atom(undefined);
export const formNamesAtom = atom([]);
export const reportDataAtom = atom({
  date: "",
  marketBasedEmission: "",
  locationBasedEmission: "",
  companyName: "",
  startDate: "",
  endDate: "",
  accuracyComments: "",
  recommendations: "",
  consultant_signature: "",
  consultant_details: "",
});
export const tableDataSourceAtom = atom([]);
