import { FilePdfOutlined, LoadingOutlined } from "@ant-design/icons";
import { message, Spin, Tooltip } from "antd";
import axios from "axios";
import isMobile from "is-mobile";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { fixDecimals } from "../../helper/wodash";
import { adminApi } from "../../static/adminApi";
import { AimImg, carbonDatabaseInitiativeLogo, combinedRowImages, communicateImg, lastPageImages, logoImage, offsetImg, reduceImg } from "./base64images/images";
import { getAnalysis } from "../extras/testing/workers/year";
import store from "../../redux/store";
import { configDataSource } from "../extras/testing/config";
import formatEmissions from "../../components/text/resultEmissions";
import mergeAndDownloadPDF from "../consultants/verification/helper";
import { firstCompany } from "../../helper/attribute";
import { updateUserClicks } from "../../firebase/user/update";
const isMobileDevice = isMobile();
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//HELPER FUNCTIONS

const fixDecimalsAndAddCommas = (amount, decimals, commas = true) => {
  if (amount == 0) return "0.00";
  if (amount < 0.01) return "<0.01";
  return fixDecimals(amount, decimals, commas);
};

const margin = (l = 0, r = 0, t = 10, b = 10) => ({
  text: " ",
  margin: [l || 0, r || 0, t || 0, b || 0],
});

function CbfPdf({
  callFunctionInsteadOfDownload: functionInsteadOfDownload = null,
  renderThisInstead = null,
  emissionParams = {
    buildings: {
      emissions: 20.23,
      energyType: "234 litres of petrol",
      title: "Buildings",
    },
    flights: { emissions: 22.8, title: "Flights" },
    carsAndRail: { emissions: 332.7, title: "Cars and Rail" },
    numberOfEmployees: 5,
    // barChartData: {
    //   officeBased: 3.5,
    //   highEnergyOrganizations: 20,
    // },
  },
}) {
  const isExtraSmall = useMediaQuery({ query: "(max-width: 599px)" });
  const [loading, setLoading] = useState(false);
  const [locationAnalysis, setLocationAnalysis] = useState(() => {
    return configDataSource(
      getAnalysis(store.getState().app.results, {
        reportType: "Emissions",
        reporting: "All",
        type: "Location",
        wtt: "Included",
      }).dataSource,
      [2024]
    );
  });
  const [marketAnalysis, setMarketAnalysis] = useState(() => {
    return configDataSource(
      getAnalysis(store.getState().app.results, {
        reportType: "Emissions",
        reporting: "All",
        type: "Market",
        wtt: "Included",
      }).dataSource,
      [2024]
    );
  });

  const titleFontSize = 16;
  const normalFontSize = 12;
  const mediumFontSizeTitle = 15;
  const smallFontSize = 10;
  const currentDate = new Date().toLocaleDateString();
  const titleColor = "#00b050";
  const cbfLinkColor = "#0563c1";
  const cbfPageLinkColor = "#0000ff";
  // DATA SUMMARY FUNCTION

  function generateEmissionDetails(emissionParams) {
    const dynamicData = [];
    let totalEmissionsMarket = 0;
    let totalEmissionsLocation = 0;

    Object.keys(emissionParams).forEach((type, index, array) => {
      if (emissionParams[type].emissions !== undefined) {
        const emDetails = emissionParams[type].details || [];

        let emissionsValue = 0;
        emDetails.forEach((e) => (emissionsValue += emissionParams[type].title.includes("Location") ? 0 : (e.resultwtt ?? 0) + (e.result ?? 0)));
        totalEmissionsMarket += emissionsValue ?? 0;

        let emissionsValueLoc = 0;
        emDetails.forEach((e) => (emissionsValueLoc += emissionParams[type].title.includes("Market") ? 0 : (e.resultwtt ?? 0) + (e.result ?? 0)));
        totalEmissionsLocation += emissionsValueLoc ?? 0;

        const title = emissionParams[type].title;

        const data = {
          margin: [0, 0, 0, 10],
          stack: [
            {
              text: title,
              fontSize: 13,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            {
              columns: [
                {
                  stack: [
                    {
                      text: "Tonnes of CO₂e",
                      margin: [0, 0, 0, 6],
                      fontSize: smallFontSize,
                    },
                    ...emDetails.map((e) => ({
                      text: fixDecimalsAndAddCommas(e.result),
                      style: "content",
                      alignment: "center",
                      fontSize: smallFontSize,
                      margin: [5, 0, 0, 0],
                    })),
                    {
                      text: fixDecimalsAndAddCommas(emDetails?.reduce((a, curr) => a + (curr.result ?? 0), 0)),
                      bold: true,
                      fontSize: 10,

                      alignment: "center",
                      margin: [5, 0, 0, 0],
                    },
                  ],
                  width: "15%",
                },
                {
                  stack: [
                    {
                      text: `WTT`,
                      fontSize: smallFontSize,

                      alignment: "center",
                      margin: [0, 0, 0, 6],
                    },
                    ...emDetails.map((e) => ({
                      text: fixDecimalsAndAddCommas(e.resultwtt),
                      style: "content",
                      fontSize: smallFontSize,

                      alignment: "center",
                      margin: [5, 0, 0, 0],
                    })),
                    {
                      text: fixDecimalsAndAddCommas(emDetails?.reduce((a, curr) => a + (curr.resultwtt ?? 0), 0)),
                      bold: true,
                      fontSize: smallFontSize,

                      alignment: "center",
                      margin: [5, 0, 0, 0],
                    },
                  ],
                  width: "10%",
                },
                {
                  stack: [
                    {
                      fontSize: smallFontSize,

                      text: `${title} Details`,
                      margin: [0, 0, 0, 6],
                    },
                    ...emDetails.map((e) => ({
                      text: e.text,
                      fontSize: smallFontSize,

                      style: "content",
                    })),
                    {
                      fontSize: smallFontSize,

                      text: `Total ${title} emissions footprint`,
                      bold: true,
                      margin: [0, 0, 0, 5],
                    },
                  ],
                  width: "90%",
                },
              ],
            },
          ],
        };

        dynamicData.push(data);
      }
    });

    return [dynamicData, totalEmissionsMarket, totalEmissionsLocation];
  }

  function generatePieDescriptionText(emissionParams) {
    const [_, totalEmissionsMarket, totalEmissionsLocation] = generateEmissionDetails(emissionParams);
    const carbonIntensity = totalEmissionsMarket / emissionParams.numberOfEmployees;

    const totalMarketEmissionsText = {
      text: `Total market-based emissions = ${fixDecimalsAndAddCommas(totalEmissionsMarket)} tonnes CO₂e`,
      margin: [0, 15, 0, 0], // Top margin
      alignment: "center",
      fontSize: normalFontSize,
    };

    const totalLocationEmissionsText = {
      text: `Total location-based emissions ${fixDecimalsAndAddCommas(totalEmissionsLocation)} tonnes CO₂e`,

      alignment: "center",
      fontSize: normalFontSize,
    };

    const carbonIntensityText = {
      text: `Market-Based Carbon intensity = ${fixDecimalsAndAddCommas(carbonIntensity)} tonnes CO₂e / employee`,
      margin: [0, 15], // Top margin
      alignment: "center",
      fontSize: normalFontSize,
    };

    const remainingText = {
      text: `Read on for the full report & recommendations`,
      margin: [0, 20], // Top margin
      alignment: "center",
      fontSize: normalFontSize,
    };

    return [totalMarketEmissionsText, totalLocationEmissionsText, carbonIntensityText, remainingText];
  }

  function cbfStepsImage() {
    return {
      margin: [0, 20, 0, 20],
      image: combinedRowImages,
      alignment: "center",
      fit: [550, 300],
    };
  }

  const [dynamicDataArray] = generateEmissionDetails(emissionParams);

  const pieDescriptionText = generatePieDescriptionText(emissionParams);

  const cbfJourneySteps = (image, text) => {
    return {
      columns: [
        { image, width: 50, height: 70 },
        {
          text,
          margin: [14, 15, 0, 0],
          color: titleColor,
          fontSize: mediumFontSizeTitle,
          bold: true,
        },
      ],
    };
  };

  // TABLE CONFIG FOR EMISSION DATA

  function emissionsTable(emissionParams) {
    return {
      columns: [
        {
          style: "tableExample",
          fontSize: 14,
          alignment: "center",
          margin: [80, 0, 0, 40],
          table: {
            widths: [170, 170],
            heights: 15,

            body: [
              [
                {
                  text: "Organisation",
                  alignment: "left",
                  bold: true,
                  fontSize: 18,
                },
                {
                  text: firstCompany()?.title,
                  alignment: "center",
                  bold: true,
                  fontSize: 18,
                },
              ],
              [
                { text: "Data entered by", alignment: "left" },
                { text: emissionParams.dataEnteredBy, alignment: "center" },
              ],
              [
                { text: "Number of employees", alignment: "left" },
                {
                  text: `${emissionParams.numberOfEmployees}`,
                  alignment: "center",
                },
              ],
              [
                { text: "Date", alignment: "left" },
                { text: currentDate, alignment: "center" },
              ],
            ],
          },
        },
      ],
    };
  }
  // TABLEE CONFIG FOR SCOPE TABLE UNDER PIECHART

  function scopeTable() {
    return {
      columns: [
        {
          // alignment: "center",
          margin: [60, 30, 50, 10],
          table: {
            headerRows: 1,
            widths: [120, 120, 120],
            body: [
              [
                { text: "", alignment: "left" },
                {
                  text: "Market Based Emissions (tCO₂e)",
                  bold: true,
                  alignment: "left",
                },
                {
                  text: "Location Based Emissions (tCO₂e)",
                  bold: true,
                  alignment: "left",
                },
              ],
              [
                { text: "Scope 1", alignment: "left", bold: "true" },
                {
                  text: fixDecimalsAndAddCommas(marketAnalysis[0][2024]),
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(locationAnalysis[0][2024]),
                  alignment: "left",
                },
              ],
              [
                { text: "Scope 2", alignment: "left", bold: "true" },
                {
                  text: fixDecimalsAndAddCommas(marketAnalysis[1][2024]),
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(locationAnalysis[1][2024]),
                  alignment: "left",
                },
              ],
              [
                { text: "Scope 3", alignment: "left", bold: "true" },
                {
                  text: fixDecimalsAndAddCommas(marketAnalysis[2][2024]),
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(locationAnalysis[2][2024]),
                  alignment: "left",
                },
              ],
              [
                {
                  text: "Total",
                  fillColor: titleColor,
                  bold: true,
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(marketAnalysis[3][2024]),
                  fillColor: titleColor,
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(locationAnalysis[3][2024]),
                  fillColor: titleColor,
                  alignment: "left",
                },
              ],
              [
                {
                  text: "Total per employee",
                  fillColor: titleColor,
                  bold: true,
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(marketAnalysis[3][2024] / (emissionParams.numberOfEmployees ?? 0)),
                  fillColor: titleColor,
                  alignment: "left",
                },
                {
                  text: fixDecimalsAndAddCommas(locationAnalysis[3][2024] / (emissionParams.numberOfEmployees ?? 0)),
                  fillColor: titleColor,
                  alignment: "left",
                },
              ],
            ],
            layout: {
              hLineWidth: function () {
                return 1; // horizontal line width
              },
              vLineWidth: function () {
                return 1; // vertical line width
              },
              paddingTop: function () {
                return 5; // padding at the top of a cell
              },
              paddingBottom: function () {
                return 5; // padding at the bottom of a cell
              },
            },
            heights: 15, // Adjust the height of the rows as needed
          },
          style: "tableExample",
          fontSize: normalFontSize,
        },
      ],
    };
  }

  //  SELF ASSESSED REPORT CONTENT

  const generateReportContent = () => {
    const normalFontSize = 12;

    return {
      fontSize: normalFontSize,
      stack: [
        {
          margin: [0, 20, 0, 5],
          text: [
            "This is a self-assessed report – showing the emissions associated with the data entered into the Sustrax carbon calculator. ",
            {
              text: "Note this is a “self-assessment” and the data has not been verified or checked by Carbon Footprint Ltd.",
              bold: true,
            },
          ],
        },
        {
          margin: [0, 5, 0, 5],
          text: "This report includes both location-based and market-based totals, in accordance with The Greenhouse Gas Protocol Corporate Standard.",
        },
        {
          margin: [0, 5, 0, 5],
          text: "The GHG Protocol’s dual-reporting method requires both location and market-based GHG emissions to be reported alongside one another.",
        },
        {
          margin: [0, 5, 0, 5],
          text: "The two methods are outlined below:",
        },
        {
          margin: [50, 5, 0, 5],
          text: [
            {
              text: "Location-based approach",
              bold: true,
            },
            " – reflects the emissions from electricity coming from the national grid energy supply. This method utilizes location-based factors.",
          ],
        },
        {
          margin: [50, 5, 0, 5],
          text: [
            {
              text: "Market-based approach",
              bold: true,
            },
            " – reflects the emissions from the electricity sources or products that the consumer has specifically chosen. This method utilizes supplier-specific factors as a preference, with residual factors being used where supplier-specific factors are not available.",
          ],
        },
        {
          margin: [0, 10, 0, 30],
          text: "The results in the report show all Greenhouse Gases (GHGs) caused by the elements included. These are reported in tonnes of CO₂ equivalent (tCO₂e) in terms of the global warming impact over 100 years.",
        },
        {
          italics: true,
          margin: [0, 5, 0, 10],
          fontSize: normalFontSize,
          bold: true,
          lineHeight: 1.1,
          text: [
            "The results have been calculated automatically using Sustrax carbon emissions software available at ",
            {
              text: "www.carbonfootprint.com",
              color: cbfLinkColor,
              decoration: "underline",
            },
            ". The software uses UK Government and other internationally recognized emissions factors.",
          ],
        },
        // {
        //   lineHeight: 1.1,
        //   bold: true,
        //   italics: true,
        //   margin: [0, 5, 0, 5],
        //   fontSize: normalFontSize,
        //   text: "Datasets have been entered entirely by the client and no checking has been done by Carbon Footprint Ltd as to the validity or completeness of the dataset. To have confidence in your results, particularly if you need to report to your supply chain/stakeholders or to promote in your markets, we strongly recommend you commission a Carbon Footprint Verification. This will add credibility to your green claims.",
        // },
      ],
    };
  };

  //  CaDI CONTENT

  function generateCADIContent(pageBreakAfter) {
    const submissionFooter = {
      margin: [90, 60, 0, 0],
      stack: [
        {
          link: "http://carbondi.com",
          text: "Now Submit & Disclose Your Business Results for FREE on",
          fontSize: normalFontSize,
        },
        {
          link: "http://carbondi.com",
          image: carbonDatabaseInitiativeLogo,
          fit: [100, 100],
          margin: [110, 0, 0, 0],
        },
        {
          link: "http://carbondi.com",
          text: "The Carbon Database Initiative",
          margin: [80, 0, 0, 0],
        },
        {
          link: "http://carbondi.com",
          text: "www.carbondi.com",
          margin: [110, 0, 0, 0],
        },
      ],
    };

    if (pageBreakAfter) {
      submissionFooter.pageBreak = "after";
    }

    return submissionFooter;
  }

  // AIM TEXT CONTENT
  function generateAimTipsContent() {
    const tipsContent = {
      pageBreak: "after",
      stack: [
        {
          text: "Reducing your carbon emissions can save you money and reduce your impact on climate change.",
          margin: [0, 5, 0, 5],
          fontSize: normalFontSize,
        },
        {
          text: "Now that you have completed your Carbon Footprint, you should consider setting Suitable Measurable Achievable Realistic and Time-bound (SMART) targets to help achieve these reductions. A few key points to consider are:",
          margin: [0, 5, 0, 5],
          fontSize: normalFontSize,
        },
        {
          margin: [30, 0, 0, 0],
          fontSize: normalFontSize,
          ul: [
            {
              text: "Set up a Carbon Management Plan and stick to it.",
            },
            {
              margin: [0, 5, 0, 5],
              text: 'Target easy carbon reductions first - even if these actions may yield only small results, these are psychologically important and will help your organisation to get a "taste" of success and develop a culture that enables you to tackle the bigger reduction opportunities.',
            },
            {
              margin: [0, 5, 0, 5],
              text: [
                "Get quantitative - use our Energy Efficiency Test to work out your capacity to save. Find it at ",
                {
                  text: "www.carbonfootprint.com/energy_efficiency_test.html",
                  link: "http://www.carbonfootprint.com/energy_efficiency_test.html",
                  color: cbfPageLinkColor,
                },
              ],
            },
            {
              text: "Be realistic - if your target is dependent on a massive capex that has yet to be signed or on a large cultural shift, you may want to be more conservative with your aims.",
            },
          ],
        },
      ],
    };

    return tipsContent;
  }

  //  REDUCE TEXT CONTENT
  function generateReduceTipsContent() {
    const implementationTipsContent = {
      pageBreak: "after",
      margin: [0, 0, 0, 20],
      stack: [
        {
          margin: [0, 5, 0, 10],
          text: `Once your targets are set, you will need to implement suitable methods to reach them.`,
          fontSize: normalFontSize,
        },
        {
          margin: [0, 5, 0, 7],
          fontSize: normalFontSize,
          text: [
            {
              text: "You will probably be heavily reliant on the actions of your teams for reductions to be achieved. Awareness campaigns including the use of tools such as informative posters and 'Lunch and Learn' sessions can help motivate such behavioral changes. ",
            },
          ],
        },
        {
          margin: [0, 7, 0, 5],
          text: "Rather than relying on a volunteer committee and carbon champions, make your scheme more official by giving specific team members ownership of the reductions and placing the targets in their KPIs. Celebrate successes with your staff when they occur and be clear on the difference and value this brings to the business. (Also see Communicate section).",
          fontSize: normalFontSize,
        },
      ],
    };

    return implementationTipsContent;
  }

  // OFFSET TEXT CONTENT
  function generateCarbonOffsettingContent() {
    const carbonOffsettingContent = [
      {
        fontSize: normalFontSize,
        stack: [
          {
            margin: [0, 10, 0, 10],
            text: "Carbon offsetting should be done in parallel with reducing your business’s carbon emissions internally. External programmes such as CDP award extra points for carbon offsetting organisations and offsetting is positively looked upon within sales tender/PQQs",
          },
          {
            margin: [0, 0, 0, 10],
            text: "Carbon offsetting also frequently supports broader CSR and community outreach programmes. However, we only advocate carbon offsetting if you also have a carbon measurement and carbon reduction plan.",
          },
          {
            margin: [0, 0, 0, 10],
            text: "Carbon Offsetting funds the solution to the climate emergency by:",
          },
          {
            ul: [
              {
                margin: [20, 5, 0, 5],
                text: "Decarbonising national grids (for renewable energy projects)",
              },
              {
                margin: [20, 5, 0, 5],
                text: "Reducing emissions (via avoided deforestation projects - e.g. protecting the Amazon)",
              },
              {
                margin: [20, 5, 0, 5],
                text: "Enabling more efficient/greener energy use (e.g. cookstoves projects)",
              },
            ],
          },
        ],
      },
      {
        margin: [0, 30, 0, 10],
        columns: [
          {
            fontSize: normalFontSize,
            text: [
              "The cost to offset your carbon emissions is likely to be very small compared with your energy costs (frequently it's less than 2% of the spend) and much easier to implement compared with a behavior change program. We offer a range of projects for you to choose from which support biodiversity, provide habitats for endangered species and support developing communities Reforestation and avoided deforestation carbon offset programs are hugely popular as they tackle one of the most potent threats to our planet. (Visit ",
              {
                text: "www.carbonfootprint.com/deforestation.html",
                link: "http://www.carbonfootprint.com/deforestation.html",
                color: cbfPageLinkColor,
              },
              " for more information). However, we also have more community and energy-focused projects.",
            ],
          },
        ],
      },
      {
        margin: [0, 20, 30, 10],
        table: {
          widths: [500],
          body: [
            [
              {
                stack: [
                  {
                    margin: [0, 25, 0, 0],
                    text: "How does Carbon Offsetting fit with Net Zero?",
                    alignment: "center",
                    bold: true,
                    fontSize: 15,
                    style: {
                      color: "black",
                      decoration: "underline",
                    },
                  },
                  {
                    text: " ",
                    margin: [0, 6],
                  },
                ],
              },
            ],
            [
              {
                margin: [20, 0, 20, 0],
                stack: [
                  {
                    alignment: "center",
                    text: "Science Based Targets Initiative refers to offsetting as Beyond Value Chain Mitigation. The organization also explains that it (offsetting) provides an urgently needed way for companies to cut emissions outside of their value chains in line with societal net-zero (see link - https://sciencebasedtargets.org/blog/net-zero-urgent-beyond-value-chain-mitigation-is-essential).",
                    fontSize: normalFontSize,
                    style: {
                      color: "black",
                    },
                  },
                  {
                    text: " ",
                    margin: [0, 4],
                  },
                ],
              },
            ],
            [
              {
                margin: [20, 0, 20, 20],
                alignment: "center",
                fontSize: normalFontSize,
                text: "Put simply, the only way to reach global net zero by 2050 is for carbon offsetting to be done now.",
                style: {
                  color: "black",
                },
              },
            ],
          ],
        },
        layout: {
          hLineColor: function (rowIndex, node, columnIndex) {
            return [0, 3].includes(rowIndex) ? "#000000" : "#64eda8";
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return "#64eda8";
          },
          vLineColor: function (rowIndex, node, columnIndex) {
            return [0, 1].includes(rowIndex) ? "#000000" : "#64eda8";
          },
        },
      },

      { pageBreak: "after", text: "" },
      generateLogoImage(),
      {
        fontSize: normalFontSize,
        margin: [0, 30, 0, 10],
        text: "	To achieve carbon neutrality, your organisation needs to adapt a carbon management process in the following order:",
      },
      {
        fontSize: normalFontSize,
        margin: [0, 10, 0, 4],
        stack: [
          {
            margin: [30, 0, 0, 8],
            text: "1.	Measure - Assess your organisation’s footprint (If you are reading this report you have already made the first step).",
          },
          {
            margin: [30, 0, 0, 8],
            text: "2.	Carbon Offset - compensate for the damage already done, by supporting carbon reduction beyond your value chain",
          },
          {
            margin: [30, 0, 0, 8],
            text: "3.	Commit and Set Targets to reduce emissions ",
          },
          {
            margin: [30, 0, 0, 8],
            text: "4.	Reduce emissions to reduce your impact on climate change and decrease the amount of offsetting needed",
          },
        ],
      },
      {
        fontSize: normalFontSize,
        margin: [0, 20, 0, 0],
        stack: [
          {
            alignment: "center",
            text: "Offset your businesses’ emissions now at :",
            bold: true,
          },
          {
            alignment: "center",
            text: `www.carbonfootprint.com/offset=${fixDecimalsAndAddCommas(totalEmissions, 0)}`,
            link: `https://www.carbonfootprint.com/offset.aspx?o=${carbonIntensity.toFixed(0)}&r=SustraxLite`,
            color: "#2121ff",
          },
          {
            alignment: "center",
            margin: [0, 15, 0, 0],
            fontSize: 11,
            text: [
              `If your emissions are above 100 tonnes CO₂e, `,
              {
                text: "contact us",
                link: "https://www.carbonfootprint.com/contact.aspx",
                fontSize: 12,
                color: "#2121ff",
              },
              " for a personalised offsetting proposal.",
            ],
          },
        ],
      },
      {
        margin: [0, 20, 0, 0],
        columns: [{ image: lastPageImages, width: 520, height: 140 }],
      },
      {
        fontSize: normalFontSize,
        stack: [
          {
            margin: [0, 30, 0, 0],
            text: "Carbon offsetting projects, which are commonly large-scale decarbonisation projects that deliver crucial emissions reductions around the globe are often found in developing countries where they have added social, educational and economic benefits. Moreover, climate change is a global issue (1 tonne CO₂ in Manchester is the same as 1 tonne CO₂ in Mumbai).",
          },
          {
            pageBreak: "after",
            alignment: "center",
            text: "www.carbonfootprint.com/carbonoffsetprojects.html",
            link: "http://www.carbonfootprint.com/carbonoffsetprojects.html",
            color: cbfPageLinkColor,
          },
        ],
      },
    ];

    return carbonOffsettingContent;
  }

  //  COMMUNICATE TEXT CONTENT
  function generateCommunicationContent() {
    const communicationContent = [
      {
        margin: [0, 0, 0, 10],
        columns: [
          {
            margin: [0, 30, 0, 0],
            stack: [{ image: communicateImg, width: 50, height: 60 }],
          },
          {
            stack: [
              {
                text: `Communicate - Internally & Externally`,
                fontSize: mediumFontSizeTitle,
                margin: [-190, 30, 0, 0],
                color: titleColor,
                bold: true,
              },
              {
                text: `Make sure you communicate your actions & achievements effectively, both within your organisation, to help develop your culture and externally to help improve your brand image.`,
                fontSize: normalFontSize,
                margin: [-190, 5, 0, 0],
              },
            ],
          },
        ],
      },
      {
        text: `When promoting externally be sure to promote your actions via all marketing channels
          available to you - such as web-site, newsletters, brochures, press releases, conferences/events and social media etc. Be sure to:`,
        margin: [0, 0, 0, 5],
        fontSize: normalFontSize,
      },
      {
        margin: [30, 0, 0, 0],
        fontSize: normalFontSize,
        ul: [
          {
            text: "Explain why climate change matters to you (visit www.carbonfootprint.com/warning.html for more information)",
          },
          {
            margin: [0, 5, 0, 5],
            text: "Be clear and accurate about what you've done",
          },
          {
            text: ` Don't be tempted to exaggerate - "green-wash" even if its unintentional can be damaging to your brands`,
          },
          {
            margin: [0, 5, 0, 5],

            text: " Evidence - use pictures more than words. Certificates, images of offset projects you are supporting and graphs of your carbon performance, all of which we can supply, can help communicate your point in a clearer and more enticing manner",
          },
          {
            text: ` Tell a story - show where you have come from, the progress you have made and what your commitment is for the future
                `,
          },
        ],
      },
      {
        margin: [0, 0, 0, 5],
        text: [
          "When promoting ",
          {
            text: "internally",
            italics: true,
          },
          ", ensure to:",
        ],
        fontSize: normalFontSize,
      },
      {
        fontSize: normalFontSize,
        ul: [
          {
            text: "Explain Climate Change & Why it matters (visit for more www.carbonfootprint,com/warning.html for more information)",
          },
          {
            margin: [0, 5, 0, 0],
            text: " Get people involved (Also see Reduce section)",
          },
        ],
      },
    ];

    return communicationContent;
  }

  function generateLogoImage() {
    const logoImageObject = {
      image: logoImage,
      alignment: "right",
      width: 50,
      height: 50,
    };

    return logoImageObject;
  }

  var [_, totalEmissions] = generateEmissionDetails(emissionParams);
  var carbonIntensity = totalEmissions / (emissionParams.numberOfEmployees ?? 1);

  const createPdf = async () => {
    updateUserClicks("download_pdf_report");

    setLoading(true);
    const {
      data: { chartImage: dataUrl },
    } = await axios.post(adminApi.generatePdfPieChart, pieChartData);

    // const {
    //   data: { chartImage: barChartDataUrl },
    // } = await axios.post(adminApi.generatePdfBarChart, barChartData);

    let dd = {
      pageSize: "A4",

      // FOOTER IS HERE

      footer: function (currentPage, pageCount) {
        const currentDate = new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        return {
          layout: "noBorders",
          margin: [0, 10],
          stack: [
            {
              columns: [
                {
                  text: `Page ${currentPage}`,
                  alignment: "left",
                  margin: [30, 0, 0, 0],
                  color: "#888",
                  fontSize: 11,
                },
                {
                  text: "www.carbonfootprint.com",
                  alignment: "center",
                  decoration: "underline",
                  color: cbfLinkColor,
                  fontSize: 11,
                },
                {
                  text: currentDate,
                  alignment: "right",
                  color: "#888",
                  margin: [0, 0, 30, 0],
                  fontSize: 11,
                },
              ],
            },
          ],
        };
      },

      content: [
        { alignment: "center", image: logoImage, width: 80, height: 80 },

        {
          alignment: "center",
          fontSize: 24,
          margin: [0, 50, 0, 60],
          stack: [
            {
              text: "CFP Self Assessed",
            },
            { text: "Carbon Footprint" },
            {
              text: "Results & Recommendation",
            },
          ],
        },
        emissionsTable(emissionParams),
        {
          columns: [
            {
              image: dataUrl,
              width: 250 * 2 * 0.8,
              height: 250 * 0.8,
              margin: [130, 10, 0, 10],
            },
          ],
        },

        // PIE CHART COMES HERE
        pieDescriptionText,

        {
          pageBreak: "before",
          margin: [0, 20, 0, 20],

          columns: [
            {
              color: titleColor,
              text: `Your Carbon Footprint Report & Carbon Management Journey
          `,
              fontSize: mediumFontSizeTitle,
              bold: true,
              style: "carbonManagementJourney",
            },
          ],
        },
        {
          columns: [
            {
              text: `Congratulations - you have completed the responsible first step of the 6 stage carbon management journey.
                     Best practice is to complete the following stages on a 12-month cyclical basis.`,
              margin: [0, -10, 0, 0],
            },
          ],
        },

        cbfStepsImage(),
        generateReportContent(),
        {
          pageBreak: "before",
          image: logoImage,
          alignment: "right",
          width: 50,
          height: 50,
        },

        {
          margins: [0, 30, 0, 40],
          columns: [
            {
              text: `Data Entered & Results`,
              color: titleColor,
              fontSize: mediumFontSizeTitle,
              bold: true,
              margin: [0, 0, 0, 30],
            },
          ],
        },

        //   /// SUMMARY of emmisionss/////

        dynamicDataArray,

        {
          fontSize: 10,
          margin: [0, 5, 0, 0],
          pageBreak: "after",
          text: "WTT (Well to Tank) are the greenhouse gas emissions caused during the production, transportation and distribution of the fuel before it is used to generate power (e.g. in buildings or vehicles etc.) ",
        },

        {
          text: "Results Summary",
          margins: [0, 30, 0, 20],
          fontSize: mediumFontSizeTitle,
          color: titleColor,
          bold: true,
        },

        {
          columns: [
            {
              image: dataUrl,
              width: 250 * 2 * 0.8,
              height: 250 * 0.8,
              margin: [130, 20, 0, 10],
            },
          ],
        },
        scopeTable(),
        generateCADIContent(true),

        generateLogoImage(),
        cbfJourneySteps(AimImg, `Aim - Setting realistic goal`),
        generateAimTipsContent(),

        generateLogoImage(),

        cbfJourneySteps(reduceImg, `Reduce - How to make it work and stay working`),
        generateReduceTipsContent(),
        generateLogoImage(),

        cbfJourneySteps(offsetImg, `Offset - Compensate carbon emissions you cannot reduce`),
        generateCarbonOffsettingContent(),
        generateLogoImage(),

        generateCommunicationContent(),
        { margin: [0, 70, 0, 0], text: "" },
        generateCADIContent(false),
      ],

      styles: {
        header: {
          fontSize: 12,
          bold: false,
        },
        anotherStyle: {
          italics: true,
          alignment: "right",
        },
        largeTextCol3: {
          fontSize: 26,
          bold: true,
          margin: [0, 20, 0, 60],
        },
        tableExample: {
          margin: [200, 5, 0, 0],
          alignment: "center",
        },
        pieChart: {
          margin: [150, 0],
        },
        pieDescriptionText: {
          fontSize: 16,
        },
        cbfListPage1: {
          fontSize: 16,
          bold: true,
          margin: [20, 10, 0, 0],
        },
        pageEndingText: {
          margin: [],
        },
        carbonManagementJourney: {
          bold: true,
        },
        rowImgMeasure: {
          margin: [0, 0, 30, 0],
        },
      },
    };
    if (typeof functionInsteadOfDownload === "function") {
      var pdfDoc = pdfMake.createPdf(dd);
      pdfDoc.getDataUrl(async function (dataUrl) {
        // var pdfFrame = document.getElementById("pdfFrame");
        // pdfFrame.src = dataUrl;
        await functionInsteadOfDownload(dataUrl);
        setLoading(false);
      });
    } else {
      pdfMake.createPdf(dd).download("CFP Self-Assessment Report");
      setLoading(false);
    }
  };

  const pieChartData = {
    labels: Object.keys(emissionParams)
      .filter((key) => emissionParams[key].hasOwnProperty("emissions") && !key.includes("Location"))
      .map((key) => emissionParams[key].title || key),
    datasets: [
      {
        label: "Monthly Sales",
        data: Object.keys(emissionParams)
          .filter((key) => emissionParams[key].hasOwnProperty("emissions") && !key.includes("Location"))
          .map((key) => emissionParams[key].emissions),
        backgroundColor: ["#f56942", "purple", "#ad5650", "yellow"],
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };
  console.log(pieChartData);
  if (renderThisInstead) {
    return (
      <Tooltip title={loading ? "Loading, please wait..." : ""}>
        <div onClick={createPdf}>
          {loading && <Spin />}
          {renderThisInstead}
        </div>
      </Tooltip>
    );
  }
  return (
    <div>
      <button
        className="download-btn ribbon-2 analysis-download-pdf"
        style={{
          backgroundColor: "#0F9309",
          border: "transparent",
          color: "white",
          cursor: "pointer",
          height: "50px",
          width: isExtraSmall ? "350px" : "380px",
          border: "1px solid darkgreen",
        }}
        onClick={!loading ? createPdf : () => message.info("Downloading!")}
      >
        {loading ? (
          <LoadingOutlined spin />
        ) : (
          <div
            style={{
              fontSize: isMobileDevice ? "12px" : "14px",
            }}
          >
            Download PDF Report <FilePdfOutlined />
          </div>
        )}
      </button>
    </div>
  );
}

export default CbfPdf;
