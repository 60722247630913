// morph results just for display

import moment from "moment";
import formatEmissions from "../../components/text/resultEmissions";
import { timeFormat } from "../../helper/time";
import { firstCompany } from "../../helper/attribute";
import { getGHGTitle } from "../../static/ghg.static";

export const morphResults = (results) => {
  return results?.map((result) => {
    const updatedResult = { ...result };

    if (result.ghg) {
      updatedResult.ghg = getGHGTitle(result.ghg);
    }

    if (result.hasOwnProperty("supplier_factors_system_generated")) {
      if (typeof result.supplier_factors !== "number") {
        updatedResult.supplier_factors = formatEmissions(result.supplier_factors_system_generated);
      }
    }

    return updatedResult;
  });
};

export const sortResults = (results) => {
  return results;
  console.log("sorting results", results);

  // Sort results based on `createdAt` or `updatedAt` if it exists
  const sortedResults = results.sort((a, b) => {
    const dateA = moment(a.updatedAt || a.createdAt, "DD/MM/YYYY").valueOf();
    const dateB = moment(b.updatedAt || b.createdAt, "DD/MM/YYYY").valueOf();
    return dateB - dateA;
  });

  console.log("sorted results", sortedResults);
  return sortedResults;
};

function getMonthNumber(monthName) {
  return moment().month(monthName).month();
}

export function extractYears(results) {
  if(!results) return [];
  const yearSet = new Set(results.map((r) => getResultDates(r).fiscalYear));
  const uniqueSortedYears = Array.from(yearSet).sort((a, b) => a - b);
  return uniqueSortedYears;
}

export function getResultDates(result) {
  // return the months and other dates
  const reportingMonthNumber = getMonthNumber(firstCompany().reporting_year_starting_month);
  const date = moment(result.date, timeFormat);
  const calendarYear = date.year();
  const calendarMonth = date.month();
  let fiscalYear, fiscalMonth;
  if (date.month() < reportingMonthNumber) {
    fiscalYear = calendarYear - 1;
  } else {
    fiscalYear = calendarYear;
  }

  if (reportingMonthNumber <= calendarMonth) {
    fiscalMonth = calendarMonth - reportingMonthNumber;
  } else {
    fiscalMonth = 11 - reportingMonthNumber + calendarMonth;
  }

  return {
    calendarYear,
    calendarMonth: calendarMonth + 1,
    fiscalYear,
    fiscalMonth: fiscalMonth + 1,
  };
}
