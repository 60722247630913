import React from "react";
import { Typography, Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";
import {
  designations,
  getCurrentUserDesignation,
} from "../../helper/attribute";
const { Title, Text, Link } = Typography;

const FooterComponent = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  return (
    <Row gutter={[12, 12]} className="notranslate">
      <Col
        style={{ marginTop: isSmall ? "" : "10px" }}
        xs={24}
        sm={24}
        md={14}
        lg={14}
        xl={14}
      >
        <div>
          <Title
            style={{
              fontSize: isExtraSmall ? "14px" : "16px",
            }}
          >
            Sustrax MX is brought to you by Carbon Footprint Ltd
          </Title>
          <Text>
            Carbon Footprint Ltd is registered in England and Wales (no.
            4532520)
          </Text>
          <br />
          <Text>
            Registered Office: Belvedere House, Basing View, Basingstoke,
            Hampshire, RG21 4HG, UK
          </Text>
        </div>
      </Col>

      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <div
          className="footer-termsAndCondition-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: isExtraSmall ? "left" : isSmall ? "left" : "right",
            marginTop: 30,
          }}
        >
          <Link
            href="https://www.carbonfootprint.com/termsandconditions.html"
            target="_blank"
            style={{ color: "forestgreen" }}
            className="text-font-footer"
          >
            Terms And Conditions
          </Link>
          <Link
            className="text-font-footer"
            style={{ color: "forestgreen" }}
            href="https://www.carbonfootprint.com/policy.html"
            target="_blank"
          >
            Cookie Policy
          </Link>
          <span
            className="footer-links"
            style={{
              display: "flex",
              flexDirection: isExtraSmall ? "column" : "row",
              gap: 10,
              marginTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <Text className="text-font-footer">
              © Carbon Footprint Ltd - all rights reserved{" "}
            </Text>
            <Link
              href="https://carbonfootprint.com"
              target="_blank"
              style={{ color: "forestgreen" }}
            >
              carbonfootprint.com
            </Link>
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default FooterComponent;
