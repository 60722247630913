import { Divider, Empty, Modal, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Select1 from "../select/select1";
import { getNextTwelveMonths } from "../../helper/time";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/features/appSlice";
import useSize from "../../hooks/useSize";
import moment from "moment";
import DownloadDomImage from "../canvas/downloadDomImage";
import * as Comlink from "comlink";

let itemColorMapping = {};


function filterNonClonable(data) {
  if (Array.isArray(data)) {
    return data.map(filterNonClonable);
  } else if (data && typeof data === "object") {
    const cloneableData = {};
    Object.keys(data).forEach((key) => {
      if (typeof data[key] !== "function") {
        cloneableData[key] = filterNonClonable(data[key]);
      }
    });
    return cloneableData;
  }
  return data;
}

export default function BarReChart({ form, results, heading, fullDataRows = [], segregate_by = false, defaultFilters }) {
  const YAXIS_OPTIONS = ["tCO₂e", "kWh", "Count"];
  const XAXIS_OPTIONS = ["Months", "Fiscal Year", "Calendar Year"];
  const { isMobile } = useSize();
  const [processedResults, setProcessedResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [segregator, setSegrigator] = useState("none");
  const [yaxis, setYaxis] = useState("tCO₂e");
  const [xaxis, setXaxis] = useState(XAXIS_OPTIONS[0]);
  const [currentYear, setCurrentYear] = useState(0);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [type, setType] = useState("Location");
  const [wtt, setWtt] = useState("Included");
  const { isDarkMode } = useSelector(selectTheme);

  useEffect(() => {
    // Initialize the worker using Comlink
    const worker = new Worker("/workers/primeWorker.js");
    const workerApi = Comlink.wrap(worker);
    const runWorker = async () => {
      setLoading(true);

      // Filter out any non-clonable data from form and results
      const filteredForm = filterNonClonable(form);
      const filteredResults = filterNonClonable(results);


      // Run the processResults function in the worker
      const processedData = await workerApi.processResults(filteredForm, filteredResults, xaxis, yaxis, type, wtt, XAXIS_OPTIONS);
      setProcessedResults(processedData);

      setLoading(false);
      worker.terminate();
    };

    runWorker();

    return () => worker && worker.terminate();
  }, [form, results, xaxis, yaxis, type, wtt]);

  const graphBarNames = getNextTwelveMonths("January", uniqueYears[currentYear], "YYYY", "MMM").map((obj) => obj.month + " " + obj.year);
  const graphDataRaw = graphBarNames.map((monthName) => {
    const filteredData = processedResults.filter((item) => item.name === monthName);
    const result = { name: monthName };

    filteredData.forEach((curr) => {
      let typeKey = yaxis;
      if (segregator !== "none" && curr[segregator]) typeKey = curr[segregator];
      result[typeKey] = (result[typeKey] || 0) + Number(curr[yaxis]);
    });
    return result;
  });

  const renderStackedBarChart = () => {
    let types = [...new Set(processedResults.map((item) => item[segregator]))];
    if (segregator === "none") types = [yaxis];

    return (
      <BarChart data={graphDataRaw}>
        <CartesianGrid />
        <XAxis dataKey="name" fontSize={11} interval={0}>
          <Label value={xaxis} position="insideBottom" offset={-10} />
        </XAxis>
        <YAxis>
          <Label value={yaxis} angle={-90} position="insideLeft" offset={-20} />
        </YAxis>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        {types.map((type, index) => {
          if (!itemColorMapping[type]) {
            itemColorMapping[type] = index % 12; // Assign colors from a color palette
          }
          return <Bar key={type + index} dataKey={type} stackId="a" fill={itemColorMapping[type]} name={type} />;
        })}
      </BarChart>
    );
  };

  if (loading) return <Empty description="Loading data..." />;
  if (!processedResults?.length) return <Empty description="No Data Available" />;

  return (
    <div>
      {heading && <Divider>{heading}</Divider>}
      <div style={{ width: "100%" }}>
        <ResponsiveContainer width="100%" height={400}>{renderStackedBarChart()}</ResponsiveContainer>
      </div>
      <Modal title="Graph Enlarged View" footer={null} visible={false} width="100%">
        <ResponsiveContainer width="100%" height="100%">{renderStackedBarChart()}</ResponsiveContainer>
      </Modal>
    </div>
  );
}
