import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firstCompany } from "../../../helper/attribute";
import { getAllParamsFromLocation } from "../../../helper/loc";
import { fixDecimals } from "../../../helper/wodash";
import { selectUserDoc } from "../../../redux/features/userSlice";
import CbfPdf from "../../pdf-report/CbfPdf";
import { getDetails } from "../../results/CombinedTable";
import { getFormName } from "./config";

const CbfReportDownload = ({ year, results = [], callFunctionInsteadOfDownload, renderThisInstead }) => {
  // const results = useSelector(selectResults);
  console.log(results, year, results);
  const userdoc = useSelector(selectUserDoc);
  const em = firstCompany()?.metrics?.[year]?.fte ?? 1;
  const [emissionParams, setEmissionParams] = useState({
    numberOfEmployees: em,
    barChartData: {
      officeBased: em * 5,
      highEnergyOrganizations: em * 30,
    },
    dataEnteredBy: userdoc?.firstName + " " + userdoc?.lastName,
    companyName: firstCompany().title,
  });
  useEffect(() => {
    //generate download data
    let excludewtt;
    if (getAllParamsFromLocation().excludewtt === "yes") excludewtt = true;
    if (results) {
      const emissionsSum = {};
      const emissionsDetails = {};
      results.forEach((result) => {
        // if (extractYear(result, true) != year) {
        //   return "only scopeYear results should be included";
        // }
        let resultresult = result.result ?? 0;
        let resultwtt = result.wtt ?? 0;
        const formName = getFormName(result.name);
        if (result.name === "electricity") {
          const details = {
            text: getDetails(result.name, result, "Market")?.report,
            result: result["marketbased_result"] + result["marketbased_tnd"],
            resultwtt: result[`marketbased_wtt_tnd`] + result["marketbased_wtt"],
          };
          // resultresult += emissionsSum[result.name] || 0; //old result
          emissionsDetails["Market Based " + formName] = emissionsDetails["Market Based " + formName] ? [...emissionsDetails["Market Based " + formName], details] : [details];
          const detailsLocationBased = {
            text: getDetails(result.name, result)?.report,
            result: result["result"] + result["tnd"],
            resultwtt: result[`wtt_tnd`] + result["wtt"],
          };
          // resultresult += emissionsSum[result.name] || 0; //old result
          emissionsDetails["Location Based " + formName] = emissionsDetails["Location Based " + formName]
            ? [...emissionsDetails["Location Based " + formName], detailsLocationBased]
            : [detailsLocationBased];
          emissionsSum["Location Based " + formName] = (emissionsSum[formName] || 0) + (detailsLocationBased.result ?? 0) + (detailsLocationBased.resultwtt ?? 0);
          emissionsSum["Market Based " + formName] = (emissionsSum[formName] || 0) + (details.result ?? 0) + (details.resultwtt ?? 0);
        } else {
          const details = {
            text: getDetails(result.name, result)?.report,
            result: resultresult,
            resultwtt: resultwtt,
          };
          // resultresult += emissionsSum[result.name] || 0; //old result
          emissionsDetails[formName] = emissionsDetails[formName] ? [...emissionsDetails[formName], details] : [details];
          emissionsSum[formName] = (emissionsSum[formName] || 0) + (result.result ?? 0) + (result.wtt ?? 0);
        }
      });
      Object.keys(emissionsDetails).forEach((key) => {
        emissionParams[key] = {
          emissions: fixDecimals(emissionsSum[key]),
          details: emissionsDetails[key],
          title: key,
        };
      });
      setEmissionParams({ ...emissionParams });
    }
  }, [results, year]);
  return (
    <div>
      <CbfPdf renderThisInstead={renderThisInstead} callFunctionInsteadOfDownload={callFunctionInsteadOfDownload} key={results} results={results} emissionParams={emissionParams} />
    </div>
  );
};

export default CbfReportDownload;
