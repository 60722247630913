import { ArrowRightOutlined, EditFilled, InfoCircleTwoTone, LinkOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Select, theme, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { T1 } from "../../appconfig/texts";
import KeyValueDisplay from "../../components/display/KeyValueGrid";
import List from "../../components/list/list";
import SelectForm from "../../components/select/selectform";
import { designations, findCompanies, getCurrentUserDesignation, getGroupDisplayData, isConsultant, openSites } from "../../helper/attribute";
import { getCurrentPlan, planValue } from "../../helper/plans";
import { selectUserDoc } from "../../redux/features/userSlice";
import { routePath } from "../../static/routes.static";
import Companies from "../admin/companies";
import Sites from "../admin/sites";
import Users from "../admin/users";
import MonthAnalysis from "../extras/testing/month.analysis";
import YearAnalysis from "../extras/testing/year.analysis";
import AddGroup from "../super-admin/Components/AddGroup";
import FooterComponent from "./footer";
import "./style.css";
import useSize from "../../hooks/useSize";
import store from "../../redux/store";
import ConsultantDashboard from "./consultant";
import { extractYears } from "../results/resultsCalcs";
import InsightResults from "./comp/insightResults";
import { selectResults } from "../../redux/features/appSlice";
// import "./bgs.css";

const { Title, Paragraph } = Typography;

const now = moment();
let greeting = "";
let clipart = "";

if (now.hour() < 12) {
  greeting = <T1 pos={"dashboard.greetings.morning"}></T1>;
  clipart = <T1 pos={"dashboard.greetings.morningIcon"}></T1>;
} else if (now.hour() < 18) {
  greeting = <T1 pos={"dashboard.greetings.afternoon"}></T1>;
  clipart = <T1 pos={"dashboard.greetings.afternoonIcon"}></T1>;
} else {
  greeting = <T1 pos={"dashboard.greetings.evening"}></T1>;
  clipart = <T1 pos={"dashboard.greetings.eveningIcon"}></T1>;
}

const WelcomeBlock = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();

  return (
    <Row gutter={[16, 16]} className="welcome-block-free-plan">
      <Col span={24}>
        <Divider />
        <Title
          style={{
            fontSize: isExtraSmall ? "20px" : isSmall ? "22px" : "24px",
          }}
        >
          Welcome,
        </Title>
        <Paragraph className="text-font-app">
          to the Business Carbon Footprint Calculator. Carbon footprint calculations are typically based on <u>annual emissions from the previous 12 months</u>.
        </Paragraph>
        <Paragraph className="text-font-app">Please begin by selecting from the emissions sources below e.g. flights.</Paragraph>
      </Col>

      <Col xs={24} sm={24} md={12}>
        <Title
          style={{
            fontSize: isExtraSmall ? "20px" : isSmall ? "22px" : "24px",
          }}
        >
          Profile{" "}
          <Link to={"/profile"} style={{ color: "inherit" }}>
            <LinkOutlined />{" "}
          </Link>
        </Title>
        <Paragraph className="text-font-app">
          To edit any of your details, enter intensity metrics, or change your country, please select your <u>name on the top left and click on profile.</u>{" "}
        </Paragraph>
      </Col>

      <Col xs={24} sm={24} md={12}>
        <Title
          style={{
            fontSize: isExtraSmall ? "20px" : isSmall ? "22px" : "24px",
          }}
        >
          Offset Emissions{" "}
          <Link to={routePath.Analysis("Years&scroll=bottom")} style={{ color: "inherit" }}>
            <LinkOutlined />{" "}
          </Link>
        </Title>
        <Paragraph style={{ fontSize: "16px" }} className="text-font-app">
          Following your calculation, you can offset your emissions through one of our climate-friendly projects.
        </Paragraph>
      </Col>
    </Row>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const userDoc = useSelector(selectUserDoc);
  const group = useSelector((state) => state.app.groups)?.[0];
  const [dataYears, setDataYears] = useState();
  const results=useSelector(selectResults)
  useEffect(()=>{
    setDataYears(extractYears(results))
  },[results])

  const [settings, setSettings] = useState({ showEditGroup: false });
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const {
    token: { colorPrimary, colorBgContainer },
  } = theme.useToken();

  const threeCardProps = {
    style: {
      background: colorBgContainer,
      width: "25%",
      borderTop: "3px solid #8870c4",
    },
    className: "card-bottom",
  };

  if (isConsultant()) return <ConsultantDashboard />;

  return (
    <div className="dash">
      <div
        className="card-top"
        style={{
          background: isExtraSmall ? "" : colorBgContainer,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "98%",
            }}
          >
            <div style={{ marginTop: isExtraSmall ? "" : isSmall ? "" : "10px" }}>
              <Typography.Title style={{ fontSize: isExtraSmall ? "14px" : "16px" }}>{moment().format("dddd, MMMM Do YYYY")}</Typography.Title>
              <Typography.Title
                style={{
                  fontSize: isExtraSmall ? "24px" : "30px",
                  paddingTop: isExtraSmall ? "" : isSmall ? "" : "5px",
                }}
              >
                {greeting},{" "}
                <span style={{ color: colorPrimary, fontWeight: 400 }} translate="no">
                  {userDoc?.firstName + " " + userDoc?.lastName}
                </span>
              </Typography.Title>
            </div>
            <div
              style={{
                fontSize: "100px",
                display: isExtraSmall ? "none" : "block",
              }}
            >
              {clipart}
            </div>
          </div>

          {planValue("welcomeBlock") ? WelcomeBlock() : null}
        </div>
      </div>
      {getCurrentUserDesignation() === designations.group_admin && (
        <div className="card-bottom" style={{ background: theme.useToken().token.colorBgContainer }}>
          <Typography.Title level={4}>
            Your Group Data{" "}
            <EditFilled
              onClick={() =>
                setSettings({
                  ...settings,
                  showEditGroup: group,
                })
              }
            />
          </Typography.Title>
          <KeyValueDisplay data={getGroupDisplayData(group)} sortArray={["name", "companies", "createdAt", "updatedAt"]} />
        </div>
      )}
      <div
        style={{
          display: planValue("canViewDashboardAnalysis") ? "flex" : "none",
          flexDirection: isExtraSmall ? "column" : "row",
        }}
      >
        {planValue("canViewMonthsAnalysis") ? (
          <div
            className="card-bottom"
            style={{
              background: colorBgContainer,
              flex: 2.5,
              borderTop: "5px solid #556acb",
            }}
          >
            <div style={{}}>
              <Typography.Title level={3} style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <T1 pos="dashboard.monthlyAnalysis"></T1>
                <div className="button-primary-1" style={{ padding: "0px 1px" }} onClick={() => navigate(routePath.Analysis("Months"))}>
                  <T1 pos="dashboard.view"></T1> <ArrowRightOutlined />
                </div>
              </Typography.Title>
              <Typography.Text>
                <small>
                  <InfoCircleTwoTone /> {findCompanies()?.[0]?.title}
                </small>
              </Typography.Text>
            </div>
            <MonthAnalysis graphOnly={true} />
          </div>
        ) : (
          ""
        )}
        <div
          className="card-bottom"
          style={{
            background: colorBgContainer,
            flex: 1.5,
            borderTop: "5px solid #b36096",
          }}
        >
          <Typography.Title level={3} style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <T1 pos="dashboard.yearlyAnalysis"></T1>
            <div className="button-primary-1" style={{ padding: "0px 5px" }} onClick={() => navigate(routePath.Analysis("Years"))}>
              <T1 pos="dashboard.view"></T1> <ArrowRightOutlined />
            </div>
          </Typography.Title>
          <Typography.Text>
            <small>
              <InfoCircleTwoTone /> {findCompanies()?.[0]?.title}
            </small>
          </Typography.Text>
          <YearAnalysis graphOnly={true} />
        </div>
      </div>
      {getCurrentPlan() !== "free" ? (
        <div
          style={{
            display: isExtraSmall ? "none" : "flex",

            justifyContent: "stretch",
          }}
        >
          <div {...threeCardProps}>
            <Typography.Title level={5}>Sites</Typography.Title>
            <List
              arr={openSites().map((x) => x.title)}
              handleClick={(itemText) => {
                const site = openSites().find((x) => x.title === itemText);
                navigate(routePath.SiteDetails(site.id));
              }}
              max={10}
            />
          </div>
          <div
            {...{
              ...threeCardProps,
              style: { ...threeCardProps.style, width: "100%" },
            }}
          >
            <InsightResults key={dataYears} years={dataYears} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "none",
            flexDirection: "column",
            justifyContent: "stretch",
          }}
        >
          <div
            className="card-bottom"
            style={{
              background: colorBgContainer,
              borderTop: "5px solid green",
            }}
          >
            <Companies />
            <Sites canAdd={false} />
            <Users canAdd={false} />
          </div>
        </div>
      )}
      <Typography.Text>
        <div
          className="card-bottom"
          style={{
            background: colorBgContainer,
            borderTop: "5px solid #bbc520",
          }}
        >
          <h2
            style={{
              fontSize: isExtraSmall ? "24px" : isSmall ? "26px" : "30px",
            }}
          >
            <u>
              <T1 pos="dashboard.quickAccess"></T1>
            </u>
          </h2>
          <SelectForm data={{}} title={<T1 pos="dashboard.inputDataForms"></T1>} />
        </div>
      </Typography.Text>

      {/**MODALS */}
      {settings.showEditGroup && <AddGroup open={settings.showEditGroup} setOpen={(showEditGroup) => setSettings({ ...settings, showEditGroup })} editModeData={settings.showEditGroup} />}
      {store.getState().app.affiliate ? (
        <></>
      ) : (
        <div
          className="card-bottom"
          style={{
            background: colorBgContainer,
            borderTop: "5px solid darkgrey",
          }}
        >
          <FooterComponent />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
