import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import React from "react";
import InLineCards from "../pages/extras/testing/comp/inlineCards";

const label_elems = [<QuestionCircleTwoTone twoToneColor="#52c41a" />];

export const vspace = (height = 10) => (
  <div style={{ height: `${height}px` }}></div>
);

export const label = (title, elemi = 0, ...options) => (
  <Tooltip title={title} {...options}>
    {label_elems[elemi]}
  </Tooltip>
);

export const beautifulObjectNumValues = (object) => {
  return (
    <InLineCards
      dataArray={Object.entries(object)?.map(([key, value]) => ({
        name: key,
        result: value,
      }))}
    />
  );
};

export function generateDropDown({
  title = "Select an item",
  items,
  onSelect,
}) {
  const menu = (
    <Menu onClick={onSelect}>
      {items.map((item) => (
        <Menu.Item key={item.id}>{item.text}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <span style={{ cursor: "pointer" }}>{title}</span>
    </Dropdown>
  );
}
