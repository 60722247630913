import { CheckCircleTwoTone, DeleteOutlined, EditTwoTone, WarningTwoTone, InfoOutlined, ExclamationCircleOutlined, ToolOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Space, Table, Typography, message, notification } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { T1 } from "../../appconfig/texts";
import Loading from "../../components/loading";
import EllipsisText from "../../components/text/EllipsisText";
import formatEmissions, { totalEmissions } from "../../components/text/resultEmissions";
import { findFormTexts, findSite, firstCompany, firstSite, openSites } from "../../helper/attribute";
import { exceldateformat, timeFormat } from "../../helper/time";
import { fixDecimals, intersection, removeIndexes, reverseObj } from "../../helper/wodash";
import { findForm } from "../../static/formRoutes";
import { getGHGTitle } from "../../static/ghg.static";
import { routePath } from "../../static/routes.static";
import InputDataPage from "../form/inputdata";
import DataForm from "../form";
import { Pair } from "../form/reviewpage";
import { isNumber } from "lodash";
import { morphResults } from "../results/resultsCalcs";
import { getCurrentPlan, planValue } from "../../helper/plans";
import { getFuzzyMatchedSiteId, returnExcelOptions } from "./helper2";
import { getFuzzyMatchedOptions } from "./intermediate";
import { FaBandAid } from "react-icons/fa";

let EDITROWINDEX = -1;
let DATABEFOREEDIT = undefined;
let MAXROWS = 2000;

function arraysToObjects(keysArray, dataArrays) {
  return dataArrays.map((dataArray) => {
    return keysArray.reduce((obj, key, index) => {
      obj[key] = dataArray[index];
      return obj;
    }, {});
  });
}

function manageMetrics(row, companyMetrics) {
  let transformedRow = {};

  for (const [key, value] of Object.entries(row)) {
    if (key.startsWith("Metric:")) {
      const metricName = key.split(":")[1].trim();
      const metricIndex = companyMetrics.findIndex((metric) => metric.title === metricName);
      if (metricIndex !== -1) {
        transformedRow[`m_${metricIndex + 1}`] = value; // Index starts at 1
      } else {
        transformedRow[key] = value;
      }
    } else {
      transformedRow[key] = value;
    }
  }

  return transformedRow;
}

export default function ReviewPage({
  data,
  setData,
  errorRows,
  settings = { type: "Default" }, // IF TYPE IS MONTHLY, TRANSFORM THE SHEET
}) {
  const form = findForm(data.name);
  const [modalStates, setModalStates] = useState({ edit: false });
  const [modalValues, setModalValues] = useState({ edit: {} });
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [companyMetrics, setCompanyMetrics] = useState(firstCompany()?.inputMetrics?.filter((metric) => metric.enabled) ?? []);
  const [processing, setProcessing] = useState(true);
  const navigate = useNavigate();

  console.log(companyMetrics, firstCompany()?.inputMetrics);
  const file = data.file;
  const formFields = {};
  findFormTexts(data.name, "forms")?.forEach((obj) => (formFields[obj.name] = obj.title));
  useEffect(() => {
    try {
      InitProcedure();
    } catch (err) {}
  }, []);

  // useEffect(() => {
  //   console.log(totalEmissions(data?.rows, true));
  //   if (totalEmissions(data?.rows, true)?.total_emissions == 0) {
  //     setData({ ...data, zeroEmissionsError: true });
  //   } else {
  //     setData({ ...data, zeroEmissionsError: false });
  //   }
  // }, [data?.rows]);
  console.log(data)
  const InitProcedure = (
    autoFix = false,
    afterFn = () => {
      console.log("processing done...");
    }
  ) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    let rows = [],
      errorRows = [];
    reader.onload = async (event) => {
      try {
        rows = event.target.result;
        const workbook = XLSX.read(rows, { type: "array" });
        let sheetName = settings.sheetName;

        const sheet = workbook.Sheets[sheetName];
        let initialData = {};
        try {
          const config = workbook.Sheets["Config"];
          if (config) {
            const configdata = XLSX.utils.sheet_to_json(config, {
              header: 1,
              raw: true,
            });
            function arrayToObject(arr) {
              return arr.reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
              }, {});
            }
            initialData = arrayToObject(configdata);
          }
        } catch (err) {}
        let fields = reverseObj(formFields);

        rows = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: null,
          blankrows: false,
        });

        if (rows?.length > MAXROWS) {
          // MAX LENGTH CHECK
          message.warning("Maximum number of rows are " + MAXROWS);
          rows = rows.slice(0, MAXROWS + 1);
        }
        data.excelRows = [...rows.slice(1, rows?.length)];
        rows[0] = rows[0].map((key) => {
          if (fields[key]) {
            return fields[key];
          } else {
            return key;
          }
        });
        rows = arraysToObjects(rows[0], rows.slice(1, rows?.length));
        console.log("ROWS IN VIEW", rows);
        if (Array.isArray(rows)) {
          // add a unique row as well.
          rows = rows.map((row) => {
            return { ...row, bulk_uploaded: true };
          });
        }

        if (settings.type === "Monthly") {
          console.log("Applying monthly settings");
          // spread rows reading months
          const newRows = [];
          const months = Array.from({ length: 12 }, (_, index) => {
            return moment().month(index).format("MMM");
          });
          function getLastDayOfMonth(year, month) {
            const lastDayOfMonth = moment(`${year}-${month}-01`, "YYYY-MMM-DD").endOf("month").format(timeFormat);
            return lastDayOfMonth;
          }
          rows?.forEach((row) => {
            console.log(row);
            const year = row.Year;
            months?.forEach((month) => {
              const em = row[month];
              if (typeof em === "number") {
                console.log(month, em);
                const transformed_date = getLastDayOfMonth(year, month);
                const newRow = { ...row };
                newRow["date"] = transformed_date;
                const { amount_key } = form;
                newRow[amount_key ?? "amount"] = em; // main key is?
                newRows.push(newRow);
              }
            });
          });
          rows = newRows;
          console.log(rows);
        }

        if (!rows.length) rows = [];

        // Adding sites

        console.log(rows);
        const form_fields = findFormTexts(data.name, "forms");

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
          const row = rows[rowIndex];
          console.log(`Processing row ${rowIndex + 1}...`);

          const siteId = getFuzzyMatchedSiteId(row["Site"], openSites, firstSite);
          delete row["Site"];

          if (true) {
            try {
              const options_complete = await returnExcelOptions(form, { ...settings, ...row });

              for (let fieldx of form_fields) {
                const field = fieldx["name"];
                if (!row[field] || row[field] === "") continue;
                console.log(`\tProcessing field: ${field}`);

                const options = options_complete.find((fieldy) => fieldy.key === field)?.excel_options;
                console.log("options complete", options_complete, options);
                if (options && options.length) {
                  console.log(`\t\tFound ${options.length} options for field "${field}"`);

                  const matched_options = getFuzzyMatchedOptions(row[field], options);

                  if (matched_options.length) {
                    if (row[field] !== matched_options[0].value) {
                      if (autoFix === false) {
                        row[field] += " [No match]";
                        row["notFoundError"] = true;
                      } else {
                        row[field] = matched_options[0].value;
                        row["notFoundError"] = false;
                      }
                    }
                    console.log(`\t\tUpdated row[${field}] to: ${row[field]}`);
                  } else {
                    console.log(`\t\tNo matches found for field "${field}".`);
                    row["notFoundError"] = true;
                    row[field] += " [No match]";
                    // not found in suggessted options as well
                  }
                } else {
                  console.log(`\t\tNo options returned for field "${field}".`);
                  // no need to show error now
                }
              }
            } catch (err) {
              console.error(`Error processing row ${rowIndex + 1}:`, err);
            }
          }
          rows[rowIndex] = {
            ...row,
            siteId,
            date: settings.type === "Monthly" ? row?.date : exceldateformat(row?.date),
          };
        }

        rows = await Promise.all(
          rows.map(async (beforerow, index) => {
            let row = beforerow;
            let packed = row;
            try {
              if (row.date === "Invalid date") throw "Invalid Date Format";
              row = { ...row, ...initialData };
              // managing metrics

              row = manageMetrics(row, companyMetrics);

              packed = await form.pack({ ...row });
              if (isNaN(packed.result)) throw "Result not calculated";
            } catch (err) {
              packed = { ...row, err };
              errorRows.push(beforerow);
            }
            // console.log("beforerow", beforerow);
            // console.log("after", packed);
            return packed;
          })
        );
        rows = rows.map((row, i) => ({ ...row, key: Number(i + 1) + ")" }));
        if (autoFix === false) DATABEFOREEDIT = JSON.parse(JSON.stringify({ ...data, rows }));
        // ERROR ROWS REMOVAL-1: FUZZY MATCH
        afterFn();
        setData({
          ...data,
          rows: await Promise.all(
            rows.map(async (row) => {
              try {
                if (row.err) {
                  row = await form.pack(row, true);
                  delete row.err;
                  return row;
                }
              } catch (err) {
              } finally {
                return row;
              }
            })
          ),
        });
      } catch (err) {
        setProcessing({ err });
      } finally {
        setProcessing(false);
      }
    };
  };
  const handleEdit = (_, index) => {
    //Edit rows before uploading
    EDITROWINDEX = data?.rows.findIndex((row) => row.key === _.key);
    setModalValues({ ...modalValues, edit: _ });
    setModalStates({ ...modalStates, edit: true });
  };
  const handleDisplayCompleteInfo = (_, index) => {
    setDisplayInfoModal(_);
  };
  const handleDeleteRows = (indexes) => {
    setSelectedRowKeys([]);
    const newData = {
      ...data,
      rows: removeIndexes(
        data.rows,
        indexes.map((i) => parseInt(i))
      ),
      excelRows: removeIndexes(
        data.excelRows,
        indexes.map((i) => parseInt(i))
      ),
    };
    setData(newData);
    DATABEFOREEDIT = JSON.parse(JSON.stringify(newData)); // because index is going to change
  };
  const output_keys = planValue("onlyDisplayInputsInCombinedResults") ? findFormTexts(form.name, "forms") : findFormTexts(data.name, "tables", true);
  const columns = [
    {
      title: "No.",
      width: 70,
      render: (item, record) => {
        return item.key;
      },
    },
    {
      title: "Site",
      width: 130,
      render: (item, record, index) => {
        return findSite(record.siteId).title;
      },
    },
    ...output_keys.map((field) => {
      const obj = {
        title: field.title,
        width: 190,
        dataIndex: field.name,
        render: (value, record, index) => {
          return (
            <div>
              {DATABEFOREEDIT.rows[index][field.name] !== value && field.name !== "ghg" && !(getCurrentPlan() === "ma" && field.name === "date") && (
                <div style={{ textDecoration: "line-through", color: "grey" }}>{DATABEFOREEDIT.rows[index][field.name]}</div>
              )}
              <div className={typeof record?.err?.split === "function" ? intersection(record?.err?.split(" "), obj.title?.split(" ")).length && "antd-cell-err" : ""}>
                {field.name.includes("ghg") ? value : isNumber(value) ? fixDecimals(value) : value}
              </div>
            </div>
          );
        },
      };
      return obj;
    }),
    {
      title: "Result",
      dontDisplayIf: planValue("onlyDisplayInputsInCombinedResults"),
      width: 160,
      render: (_, record, index) => {
        if ((!_.result && _.result !== 0) || _.notFoundError)
          return (
            <small style={{ color: "red" }}>
              Result not calculated. Please{" "}
              <a onClick={() => handleEdit(_, index)} style={{ color: "red", textDecoration: "underline" }}>
                click here
              </a>
              .
            </small>
          );
        if ("result" in _ && _.result !== NaN && _.result !== undefined) {
          return (
            <Space>
              {/* <CheckSquareTwoTone twoToneColor={"skyblue"} />{" "} */}
              {formatEmissions(_.result)}
              <CheckCircleTwoTone twoToneColor={"green"} />
            </Space>
          );
        } else {
          return (
            <Space>
              <WarningTwoTone twoToneColor={"red"} />
              <small>{_.err || "Calculation error! "}</small>
            </Space>
          );
        }
      },
    },
    {
      title: "Comment",
      width: 200,
      dataIndex: "comment",
      render: (_) => (
        <small>
          <EllipsisText maxLength={20} text={_} />
        </small>
      ),
    },
    {
      title: "Data Link",
      width: 140,
      dataIndex: "dataLink",
      render: (_) => <small>{_}</small>,
    },
    {
      title: "Data Tag",
      width: 140,
      dataIndex: "resulttag",
      render: (_) => <small>{_}</small>,
    },
    {
      title: "Cost",
      width: 140,
      dataIndex: "cost",
      render: (_) => <small>{_}</small>,
    },
    ...companyMetrics?.map((metric, index) => {
      const dataIndex = "m_" + (index + 1);
      return {
        title: metric.title,
        dataIndex,
        key: dataIndex,
        width: 140,
      };
    }),
    {
      title: "Options",
      fixed: "right",
      width: 150,
      render: (_, record, index) => {
        return (
          <Space>
            <Button size="small" type="primary" style={{ background: "grey" }} onClick={() => handleDisplayCompleteInfo(_, index)}>
              <InfoOutlined />
            </Button>
            <Button type="primary" size="small" onClick={() => handleEdit(_, index)}>
              <EditTwoTone twoToneColor={"white"} />
            </Button>
            <Button type="primary" size="small" style={{ background: "firebrick" }} onClick={() => handleDeleteRows([index])}>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ].filter((x) => (x.dontDisplayIf ? false : true));
  const handleExport = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    console.log(data);
    const rows = data.excelRows.filter((_, index) => data.rows[index]?.err);
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(rows);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a buffer
    const buffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "output.xlsx";

    // Programmatically trigger the download
    link.click();

    // Clean up the temporary anchor element
    URL.revokeObjectURL(link.href);
  };
  if (processing)
    return (
      <Loading
        title={
          <div>
            Generating Data <span id="done-ids"></span>. This might take a while.
          </div>
        }
      />
    );
  if (processing.err) {
    return <Alert type="error" message={processing.err} />;
  }

  const info_output_keys = planValue("onlyDisplayInputsInCombinedResults") ? findFormTexts(form.name, "forms") : findFormTexts(form.name, "reviewpages", true);
  const handleAutoFix = () => {
    Modal.confirm({
      title: "Start Auto-Correction?",
      content: "We're using fuzzy matching to help identify and fix any errors. This process ensures improved accuracy and faster results.",
      onOk: () => {
        const button = document.querySelector("#autoFixButton"); // Select button by ID
        if (button) {
          // Change button content to show loading
          button.innerHTML = `<span> Loading...</span>`;
        }

        // Call InitProcedure (synchronous)
        InitProcedure(true, () => {
          // Restore original button content after InitProcedure completes
          if (button) {
            if (errorRows()) {
              button.insertAdjacentHTML(
                "afterend",
                `<div id="error-message" style="color: red; margin-top: 10px; font-size: 14px; font-weight: 500;">Some errors are still left. Please fix them individually using the edit option.</div>`
              );
            }
            button.innerHTML = `Auto Fix Applied✅`;
            button.style = { ...button.style, backgroundColor: "lightgrey", borderColor: "darkgrey" };
            button.disabled = true;
          }
        });
      },
    });
  };

  return (
    <div>
      <Typography.Title level={3}>{form.title} Bulk Upload</Typography.Title>
      <Typography.Text>Please review data below before uploading.</Typography.Text>
      {errorRows() ? (
        <Alert
          style={{
            marginBottom: "16px",
            padding: "20px",
          }}
          showIcon
          icon={<ExclamationCircleOutlined style={{ color: "#D93025" }} />}
          type="error"
          message={
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {errorRows() + " out of " + data?.rows?.length + " rows have errors."}
              <Button
                type="primary"
                onClick={handleAutoFix}
                id="autoFixButton"
                style={{
                  backgroundColor: "#FF4D4F",
                  borderColor: "#FF4D4F",
                  borderRadius: "5px",
                  fontWeight: "600",
                  padding: "0 12px",
                  fontSize: "0.9rem",
                }}
                icon={<ToolOutlined />}
              >
                Auto-Fix Errors
              </Button>
            </div>
          }
          description={<span style={{ fontSize: "0.85rem", fontWeight: "500" }}>Please correct them before uploading.</span>}
        />
      ) : null}

      {data.zeroEmissionsError ? <Alert type="warning" showIcon message="Zero Emissions" description="Total emissions are calculated to be zero. Please recheck the table before uploading. " /> : ""}

      {
        <Table
          rowKey={(record, i) => i + "-row"}
          pagination={false}
          title={() => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ border: "", display: "flex", alignItems: "center" }}>
                ℹ️.{"  "}
                {totalEmissions(data?.rows)}{" "}
              </div>
              <Space>
                <Button type="primary" onClick={handleExport}>
                  <T1 pos="bulk.downErrRowsBtn" />
                </Button>
                {selectedRowKeys.length ? (
                  <Button type="primary" style={{ background: "firebrick" }} onClick={() => handleDeleteRows(selectedRowKeys.map((key) => parseInt(key?.replace(")", ""))))}>
                    Delete {selectedRowKeys.length} rows
                  </Button>
                ) : null}
              </Space>
            </div>
          )}
          dataSource={morphResults(data?.rows)}
          rowClassName={(record, index) => (record.err ? "antd-table-row-red" : DATABEFOREEDIT?.rows[index]?.err && record.result && "antd-table-row-warning")}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
              console.log(selectedRowKeys);
            },
          }}
          scroll={{
            x: 1200,
          }}
        />
      }
      {/**MODALS */}
      <Modal
        destroyOnClose
        title={"Edit Row"}
        width={1400}
        okText="Update"
        open={modalStates.edit}
        onCancel={() => setModalStates({ ...modalStates, edit: false })}
        onOk={async () => {
          try {
            // todo: take out the notfound error from here.
            data.rows[EDITROWINDEX] = {
              ...(await form.pack({ ...modalValues.edit, notFoundError: false })),
            };
            delete data.rows[EDITROWINDEX]["err"];
            const newData = JSON.parse(JSON.stringify(data));
            newData.file = data.file;
            setData(newData);

            setModalStates({ edit: false });
          } catch (err) {
            message.error(err);
          }
        }}
      >
        <>
          {/* <ImageCurrentRow /> */}
          <InputDataPage
            data={{ ...modalValues.edit, name: form.name, siteId: data.siteId }}
            setData={(newData) => setModalValues({ ...modalValues, edit: newData })}
            isBulkPage={true}
            bulkPageErr={modalValues.edit.err}
          />
        </>
      </Modal>
      {displayInfoModal && (
        <Modal open={displayInfoModal} onCancel={() => setDisplayInfoModal(false)} onOk={() => setDisplayInfoModal(false)}>
          <>
            <Typography.Title level={3}>Complete information</Typography.Title>
            {info_output_keys.map((textObj, i) => (
              <Pair
                key={i + "pair"}
                name={textObj.name}
                option={textObj.title}
                value={textObj.name.includes("ghg") ? getGHGTitle(displayInfoModal[textObj.name]) : displayInfoModal[textObj.name]}
                formname={displayInfoModal.name}
                description={textObj.desc}
              />
            ))}
          </>
        </Modal>
      )}
    </div>
  );
}
