import { doc, updateDoc } from "firebase/firestore";
import { getCurrentDate } from "../../helper/time";
import { setCompanies } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";

const beforeuserUpdate = (data) => {
  data.updatedAt = getCurrentDate();
  return data;
};

export const updateCompany = async (id, data) => {
  data = beforeuserUpdate(data);
  await updateDoc(doc(db, firestoreStatic.companies, id), data);
  store.dispatch(
    setCompanies(
      store
        .getState()
        .app.companies.map((company) =>
          company.id !== id ? company : { ...company, ...data }
        )
    )
  );
};
