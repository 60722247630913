import { message } from "antd";
import currencies from "../assets/currencies.json";
import electricityData from "../assets/electricityData.json";
import electricityTndData from "../assets/electricityTndData.json";
import FuelKwh from "../assets/fuelkwh.json";
import paper_weights from "../assets/paper_weights.json";
import { CalculateFlightResult, convertIATA } from "../helper/airport";
import { findFormText, findResult, findSite, firstCompany } from "../helper/attribute";
import { convertCurrency } from "../helper/currency";
import { filterFactors, getFactors, propNotPresentCheck } from "../helper/emissionfactors";
import { fuzzyPropsReplace } from "../helper/fuzzy";
import { removePropsFromObject } from "../helper/obj";
import { getCurrentPlan, planValue } from "../helper/plans";
import { appendNameToProperties, objDontHave } from "../helper/wodash";
import Paper from "../pages/form/dataforms/Paper";
import Water from "../pages/form/dataforms/Water.js";
import Additional from "../pages/form/dataforms/additional";
import BulkMaterials from "../pages/form/dataforms/bulk_materials";
import Cars from "../pages/form/dataforms/cars";
import Commuting from "../pages/form/dataforms/commuting";
import Electricity from "../pages/form/dataforms/electricity";
import Flight, { getFlightPropsBasedOnAirports } from "../pages/form/dataforms/flight";
import Frieghting from "../pages/form/dataforms/freighting";
import Fuel from "../pages/form/dataforms/fuel";
import HomeWorkers from "../pages/form/dataforms/home_workers";
import Hotel from "../pages/form/dataforms/hotel.js";
import OtherTravels from "../pages/form/dataforms/other_travels";
import Product from "../pages/form/dataforms/product";
import Refrigerator from "../pages/form/dataforms/refrigerator.js";
import Spending from "../pages/form/dataforms/spending.js";
import Waste from "../pages/form/dataforms/waste.js";
import { extractYear } from "../pages/form/helper/getter";
import store from "../redux/store";
import vars from "./attributes.static";
import { findGHG, getGHGCode } from "./ghg.static";
import ids from "./ids.forms";
import HeatAndSteam, { HeatAndSteamTableConfig } from "../pages/form/dataforms/heatandsteam.js";
import { compareDates, getCurrentDate, reverseExcelDateFormat } from "../helper/time.js";
import Ingredients, { filterFactorsIngredients } from "../pages/form/dataforms/ingredients.js";
import NaturalGas from "../pages/form/dataforms/naturalgas.js";

// For each form we have to perform the following steps below
// CHECKING REQUIRED DATA
// CALCULATING RESULT
// SAVING ID, which can be used in the future
// CALCULATING WTT
// CALCULATING SCOPE
// CALCULATING GHG
// CALCULATING GHG WTT
// CALCULATING KWH
// RETURN RESULT

const mappings = [
  { source: "Comment", target: "comment" },
  { source: "Data Link", target: "dataLink" },
  { source: "Data Tag", target: "resulttag" },
  { source: "Cost", target: "cost" },
];

const commonChecks = (data, fieldsCheck, zero_check = true) => {
  if (data.notFoundError) throw "Fix errors";
  if (compareDates(data.date, "01/01/2019") === -1) throw "Date out of range";
  // if ma plan set the date to something
  if (getCurrentPlan() === "ma") {
    // do ma stuff
    const defaultDateTo = firstCompany()?.defaultDateTo;
    if (typeof defaultDateTo === "string" && defaultDateTo !== "Invalid date") {
      data.date = defaultDateTo;
      console.log("Defaulting date to incase of default date present", defaultDateTo);
    } else if (data.bulk_uploaded) {
      data.date = getCurrentDate();
    }
  }
  mappings.forEach((mapping) => {
    if (data.hasOwnProperty(mapping.source)) {
      data[mapping.target] = data[mapping.source];
      delete data[mapping.source];
    }
    if (data[mapping.target] === "") delete data[mapping.target];
  });
  try {
    //list of checks common in all of them
    if (data.amount < 0) throw "Amount should not be negative";
    else if (data.distance < 0) throw "Distance should not be in negativev";
    // date checking

    if (fieldsCheck && objDontHave(data, fieldsCheck, zero_check)) {
      throw "Please input " + findFormText(data.name, objDontHave(data, fieldsCheck));
    }
    // if (
    //   !emissionFactorsYears().includes(
    //     moment(data.date, "DD/MM/YYYY").get("year")
    //   )
    // )
    //   throw "Date out of range";
  } catch (err) {
    if (typeof err === "object") throw err.message;
    throw err;
  }
};

const convertToMiles = (unit, amountMultiplier) => {
  if (unit === "miles") {
    return amountMultiplier;
  } else if (unit === "km") {
    return amountMultiplier / 1.609;
  } else {
    return 0;
  }
};
const att_excel_vs_forms = {
  "Level 2": "category",
  UOM: "uom",
  "Level 3": "type",
  // "Column Text": "columntext",
  // "Level 4": "level4",
};
export { att_excel_vs_forms, dataForms };
let dataForms = [
  {
    title: "Fuel",
    dataFields: () => {
      // returns data fields on which results are calculated
      return ["amount"];
    },
    name: "fuel",
    fields: ["date", "type", "uom", "amount"],
    fieldTitles: {},
    filterer: (data, fuzzyChecking = false) => {
      const filterBy = {
        "Level 2": data.category,
        "Level 3": data.type,
        UOM: data.uom,
      };
      if (!data.category) delete filterBy["Level 2"];
      try {
        if (fuzzyChecking) {
          const updatedFilterBy = fuzzyPropsReplace(filterBy);
          //changing data
          Object.keys(att_excel_vs_forms).forEach((att) => {
            if (att in updatedFilterBy) {
              data[att_excel_vs_forms[att]] = updatedFilterBy[att];
            }
          });
          return updatedFilterBy;
        } else {
          return filterBy;
        }
      } catch (err) {
        return filterBy;
      }
    },
    kwhrowcode: (rowcode, key = "7") => {
      const parts = rowcode.split("_");
      if (parts.length > 3) {
        parts.splice(3, 1, key);
      }
      return parts.join("_");
    },
    calcOutOfScopeE: (code, year) => {
      let parts = code.split("_");
      parts[0] = "99"; // Change the first number
      parts[parts.length - 1] = "2"; // Change the last number
      const outOfScopeCode = parts.join("_");

      const value = filterFactors({ ID: outOfScopeCode }, year)[0];

      return value;
    },

    calculateFuelKwh: function (what) {
      const tempKwhNetCvs = {
        "Diesel (average biofuel blend)": 0.25409,
        "Petrol (average biofuel blend)": 0.23397,
      };
      return tempKwhNetCvs[what];
    },
    pack: function (data, fuzzyChecking = false) {
      // CHECKING REQUIRED DATA
      commonChecks(data, this.fields);
      // CALCULATING RESULT
      let year = extractYear(data);
      const filterBy = this.filterer(data, fuzzyChecking);
      propNotPresentCheck(filterBy, this.fieldTitles);

      const rows = filterFactors(filterBy, year);
      const code = filterFactors(filterBy, "ID")[0];
      const conversion_factor = rows[0];
      const result = (conversion_factor * data.amount) / 1000;

      // CALCULATING WTT
      // info: add one before the code of result
      // select wttcode for fuel or bioenergy
      let wttcode;
      let kwhcode = 0,
        kwh_net_cv = 1,
        kwh = 0;
      if (code.startsWith("1_")) {
        // fuel
        wttcode = `1${code}`;
        kwhcode = this.kwhrowcode(code);
        kwh_net_cv = filterFactors({ ID: kwhcode }, year)[0];
      } else {
        if (code.startsWith("2_103")) {
          //biofuel
          wttcode = code.replace("2_103", "12_900");
        } else if (code.startsWith("2_104")) {
          //biomass
          wttcode = code.replace("2_104", "12_901");
        } else if (code.startsWith("2_105")) {
          wttcode = code.replace("2_105", "12_902");
        }
        //kwh from the fuelkwh

        const key = Object.keys(FuelKwh).find((key) => {
          return key?.toLowerCase().includes(data?.type.toLowerCase()) || data?.type?.toLowerCase().includes(key.toLowerCase());
        });
        if (key) kwh_net_cv = FuelKwh[key][2023];
      }
      // alert(kwh_net_cv);

      kwh = ((result * 1000) / kwh_net_cv).toFixed(2);
      const wttFilterBy = { ID: wttcode };
      const wttyearvalue = filterFactors(wttFilterBy, year)[0];
      const wtt = (wttyearvalue * data.amount) / 1000;
      // CALCULATING SCOPE
      const scope = 1;
      // CALCULATING GHG
      const ghg = getGHGCode(2);
      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }
      // check for outside of scope emissions
      const toReturn = {
        ...data,
        result,
        scope,
        ghg,
        ghgwtt,
        kwh,
        wtt,
        code,
        wttcode,
        kwhcode,
      };
      const outofscopeemissions = this.calcOutOfScopeE(code, year);
      if (outofscopeemissions) {
        toReturn[vars.result["Out Of Scope Emissions"]] = (outofscopeemissions * data.amount) / 1000;
      }

      //checking outofscopeemissions
      console.log(toReturn);
      return toReturn;
    },
    component: Fuel,
    bg: require("./logos/Fuel.png"),
  },
  {
    title: "Natural Gas",
    dataFields: () => {
      // returns data fields on which results are calculated
      return ["amount"];
    },
    name: "naturalgas",
    fields: ["date", "type", "uom", "amount"],
    fieldTitles: {},
    filterer: (data, fuzzyChecking = false) => {
      const filterBy = {
        "Level 2": data.category,
        "Level 3": data.type,
        UOM: data.uom,
      };
      if (!data.category) delete filterBy["Level 2"];
      try {
        if (fuzzyChecking) {
          const updatedFilterBy = fuzzyPropsReplace(filterBy);
          //changing data
          Object.keys(att_excel_vs_forms).forEach((att) => {
            if (att in updatedFilterBy) {
              data[att_excel_vs_forms[att]] = updatedFilterBy[att];
            }
          });
          return updatedFilterBy;
        } else {
          return filterBy;
        }
      } catch (err) {
        return filterBy;
      }
    },
    kwhrowcode: (rowcode, key = "7") => {
      const parts = rowcode.split("_");
      if (parts.length > 3) {
        parts.splice(3, 1, key);
      }
      return parts.join("_");
    },
    calcOutOfScopeE: (code, year) => {
      let parts = code.split("_");
      parts[0] = "99"; // Change the first number
      parts[parts.length - 1] = "2"; // Change the last number
      const outOfScopeCode = parts.join("_");

      const value = filterFactors({ ID: outOfScopeCode }, year)[0];

      return value;
    },

    calculateFuelKwh: function (what) {
      const tempKwhNetCvs = {
        "Diesel (average biofuel blend)": 0.25409,
        "Petrol (average biofuel blend)": 0.23397,
      };
      return tempKwhNetCvs[what];
    },
    pack: function (data, fuzzyChecking = false) {
      data.type = "Natural gas";
      data.category = "Gaseous fuels";
      return findForm("fuel").pack(data, fuzzyChecking);
    },
    component: NaturalGas,
    bg: require("./logos/NaturalGas.png"),
  },
  {
    title: "Heat and steam",
    dataFields: () => {
      // returns data fields on which results are calculated
      return ["amount"];
    },
    name: "heatandsteam",
    dataFields: () => {
      // returns data fields on which results are calculated
      return ["amount"];
    },
    fields: ["date", "amount"],
    fieldTitles: {
      date: "Date of Emissions",
      q1: "onsite or district",
      amount: "Amount",
    },
    component: HeatAndSteam,
    pack: function (data, fuzzyChecking, options) {
      console.log("packing data", data, "options:", options);

      // CHECKING REQUIRED
      commonChecks(data, this.fields);

      /**
       *
       * kwh * factor/1000, save in the respective fields
       */

      const dontHv = objDontHave(data, this.fields, false);
      if (dontHv) throw "Please input " + dontHv;
      // CALCULATING RESULT

      const year = extractYear(data);
      const { amount, date, q1 } = data;
      const allKeys = ["result", "wtt", "wtt_tnd", "tnd"]; // remove all keys, because the user might have changed option
      allKeys.forEach((key) => {
        delete data[key];
      });

      const configs = HeatAndSteamTableConfig[q1];

      Object.entries(configs).forEach(([key, config]) => {
        const factor = filterFactors(config, year)?.[0];
        data[key] = (factor * amount) / 1000;
      });

      // CALCULATING SCOPE

      const scope = 2;
      // CALCULATING GHG

      const ghg = getGHGCode(5);
      const tndghg = getGHGCode(8);

      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }

      // RETURNING RESULT
      const toReturn = {
        ...data,
        scope,
        ghg,
        ghgwtt,
        wtt_tnd_ghg: tndghg,
        tndghg,
      };
      console.log(toReturn);
      return toReturn;
    },
    bg: require("./logos/Heat and Steam.png"),
  },
  {
    title: "Electricity",
    name: "electricity",
    dataFields: () => {
      // returns data fields on which results are calculated
      return ["amount"];
    },
    fields: ["date", "amount"],
    fieldTitles: {
      date: "Date of Emissions",
      q1: "Supplier Specific Emissions (Yes/No)",
      supplier_factors: "Supplier Factors",
      amount: "Amount",
      marketbased_tnd: "Market Based TND",
      marketbased_result: "Market Based Result",
      marketbased_wtt: "Market Based WTT",
      marketbased_wtt_tnd: "Market Based WTT & TND",
    },
    component: Electricity,
    pack: function (data, fuzzyChecking, options) {
      /*
      Factors: 
      1. provided by user
      use the users factor in all cases
      2. not provided by the user
      use the default country one. or the user can change them in the above country section
      */
      /*
       Info: We will use the last years electricty data: for UK 2023, it will be 0.19
       We have two types of ghg here tnd ghg and default one
       */

      console.log("packing data", data, "options:", options);

      // CHECKING REQUIRED
      commonChecks(data, this.fields);

      const dontHv = objDontHave(data, this.fields, false);
      if (dontHv) throw "Please input " + dontHv;
      // CALCULATING RESULT
      /**
       * There are two types of results here, location based and market based. By default we can say location based are the factors saved there.
       */

      let result;
      let bulk_uploaded = data?.bulk_uploaded; // if true: ifblank=>use the site tab supplier_factors first, if not available then use the default country supplier_factors
      let year = extractYear(data);
      let country = findSite(data.siteId).country;
      let supplier_factors_not_provided = typeof data.supplier_factors !== "number";
      const amount = data.amount / 1000;
      let row = electricityData.find((e) => e.Entity === country); // to be used in location based
      let rowtnd = electricityData.find((e) => e.Entity === country);

      //quick fix: seperate the data
      if (data.id) [options] = findResult(data.id, true);

      console.log("debug 29 packing data", data, "options:", options);

      if (data?.newsupplierfactortag || options?.newsupplierfactortag) {
        country = options?.newsupplierfactor_country ?? data?.newsupplierfactor_country;
        row = electricityData.find((e) => e.Entity === country);
        rowtnd = electricityData.find((e) => e.Entity === country);
      }
      if (supplier_factors_not_provided) {
        if (bulk_uploaded && typeof findSite(data.siteId).supplier_factors === "number") {
          //if bulk upload and blank
          // use the default ones
          const defaultFactorsForSite = findSite(data.siteId).supplier_factors;
          console.log(defaultFactorsForSite, "default factors exist");
          data.supplier_factors = defaultFactorsForSite;
          supplier_factors_not_provided = false;
        } else {
          const rm_factor_keys = Object.keys(row).filter((key) => key.includes("RM"));
          const residual_mix_factor = row["RM " + year] ? row["RM " + year] : row[rm_factor_keys[rm_factor_keys.length - 1]];
          data.supplier_factors_system_generated = residual_mix_factor;
        }
      } else {
        delete data.supplier_factors_system_generated;
      }

      //calculation of location based
      result = row[year] * amount;
      const tnd = rowtnd["T&D " + year] * amount, // already getting seperated out
        wtt = (row[`WTT ${year}`] ?? row[`WTT ${year}`]) * amount,
        wtt_tnd = (row[`WTT of T&D ${year}`] ?? row[`WTT of T&D ${year}`]) * amount;

      console.log(row[`WTT ${year}`], row[`WTT of T&D ${year}`], row[`WTT`], row[`WTT of T&D`], row, "ciao");

      //calculation for market based
      const marketBasedResults = {
        result: undefined,
        tnd: undefined,
        wtt: undefined,
        wtt_tnd: undefined,
      };
      // RESULT FOR MARKET BASED
      marketBasedResults.tnd = tnd;
      marketBasedResults.wtt = wtt;
      marketBasedResults.wtt_tnd = wtt_tnd;
      marketBasedResults.result = ((supplier_factors_not_provided ? data.supplier_factors_system_generated : data.supplier_factors) * data.amount) / (1000 * 1000);
      if (data.supplier_factors === 0) {
        // removed data.q1 === "Yes" &&  no need of it now
        marketBasedResults.tnd = 0;
        marketBasedResults.wtt = 0;
        marketBasedResults.wtt_tnd = 0;
      }
      // CALCULATING SCOPE

      const scope = 2;
      // CALCULATING GHG

      const ghg = getGHGCode(5);
      const tndghg = getGHGCode(8);

      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }
      // CALCULATING KWH
      const kwh = amount * 1000;
      // RETURNING RESULT
      const toReturn = {
        ...data,
        scope,
        ...appendNameToProperties(marketBasedResults, "marketbased_"),
        tndghg,
        tnd,
        ghgwtt,
        wtt,
        wtt_tnd,
        wtt_tnd_ghg: tndghg,
        kwh,
        ghg,
        result,
        country,
      };
      console.log(country, row, toReturn, "-for electricity form");

      if (supplier_factors_not_provided) {
        toReturn[vars.result["Overwritten Supplier Factors"]] = true; // supplier factors overwritten
      }
      console.log("returning", toReturn);
      return toReturn;
    },
    bg: require("./logos/Electricity.png"),
  },

  {
    title: "Cars",
    name: "cars",
    dataFields: (data) => {
      // returns data fields on which results are calculated
      const q1 = data["q1"];
      if (q1 === "Yes") {
        return ["amount"];
      } else {
        return ["distance"];
      }
    },
    // warning: formtypes index is being used in pack function so add other forms in the end
    formTypes: ["Company Owned", "Employee Owned", "Hire Car"],
    fields: ["date", "form", "q1", "type", "uom"],
    showInExtra: ["q1"],
    fieldTitles: {
      date: "Travel Date",
      form: "Type",
      type: "Cars (By Size)/ Fuel Type",
      distance: "Amount/ Distance",
      columntext: "Column Text",
      q1: "Do you know the amount of fuel used?",
    },
    motorBikefieldTitles: {
      date: "Travel Date",
      form: "Type",
      type: "Cars (By Size)/ Fuel Type",
      distance: "Amount/ Distance",
      q1: "Do you know the amount of fuel used?",
    },

    pack: function (data) {
      // CHECKING REQUIRED DATA
      commonChecks(data, this.fields);
      // CALCULATING RESULT
      let year = extractYear(data);
      const isMotorbike = data.type?.includes("Motorbike");
      if (isMotorbike) delete data["columntext"];
      if (data.q1 === "No" && isMotorbike && data.columntext === null) {
        return message.error("Please input column text");
      }
      let filterBy;
      if (data.q1 === "No") {
        if (!data.distance) return message.error("Please input Distance!");
        delete data.amount;
        // if motorbike column text is not needed

        filterBy = {
          Scope: "Scope 1",
          "Level 1": "Passenger vehicles",
          "Level 2": "Cars (by size)",
          "Level 3": data.type,
          UOM: data.uom,
          "Column Text": data.columntext,
        };
        if (isMotorbike) {
          filterBy["Level 2"] = "Motorbike";
          delete filterBy["Column Text"];
        }
      } else {
        if (!data.amount) return message.error("Please input Amount!");
        delete data.distance;
        filterBy = {
          Scope: "Scope 1",
          "Level 3": data.type,
          UOM: data.uom,
        };
      }
      const am = data.distance || data.amount;
      propNotPresentCheck(filterBy, this.fieldTitles);
      const rows = filterFactors(filterBy, year);
      const code = filterFactors(filterBy, "ID")[0];
      const conversion_factor = rows[0];
      const result = (conversion_factor * am) / 1000;
      // CALCULATING WTT
      let wttcode;
      let kwhcode;
      let kwh;
      if (data.q1 === "No") {
        // wtt code 4_301_3058_9_1 => 26_904_3058_9_1
        // kwh code 4_301_3058_9_1 => 6_301_3058_9_5
        wttcode = (isMotorbike ? "26_905_" : "26_904_") + code.toString().split("_").slice(2).join("_");
        kwhcode = `${isMotorbike ? "6_302_" : "6_301_"}${code.toString().split("_")[2]}_9_5`;
        const kwhrow = filterFactors({ ID: kwhcode }, year)[0];
        console.log(data);
        kwh = kwhrow * convertToMiles(data.uom, am);
      } else {
        wttcode = `1${code}`;
        kwhcode = findForm("fuel").kwhrowcode(code);
        const kwhrow = filterFactors({ ID: kwhcode }, year)[0];
        kwh = (result * 1000) / findForm("fuel").calculateFuelKwh(data.type);
      }
      const wttrow = filterFactors({ ID: wttcode }, year)[0];
      const wtt = (wttrow * am) / 1000;

      // CALCULATING SCOPE
      const scope = 3;

      // CALCULATING GHG
      const ghg = data.form === this.formTypes[0] ? getGHGCode(3) : getGHGCode(11);
      // CALCULATING GHG WTT
      let ghgwtt;
      if (data.form !== this.formTypes[0]) {
        ghgwtt = ghg; //use the same category
      } else {
        ghgwtt = getGHGCode(8); // use the 3.3 category
      }
      // RETURN RESULT
      return {
        ...data,
        result,
        ghg,
        ghgwtt,
        scope,
        wtt,
        code,
        wttcode,
        kwhcode,
        kwh,
      };
    },
    component: Cars,
    bg: require("./logos/Cars.png"),
  },
  {
    title: "Freighting ",
    name: "freighting",
    dataFields: (data) => {
      // returns data fields on which results are calculated
      return ["distance"];
    },

    fields: ["date", "category", "type", "ownership", "distance"],
    fieldTitles: {
      date: "Emissions Date",
      category: "Frieght Type",
      type: "Frieght Sub-Type",
      uom: "UOM",
      distance: "Distance",
    },
    showInExtra: ["columntext"],
    pack: function (data) {
      //CHECKING REQUIRED DATA
      commonChecks(data, this.fields);
      convertIATA(data);
      // CALCULATING RESULT
      let year = extractYear(data);
      let distance = 0;
      let uom = data.uom;
      // Check for whole vehicle
      if (data.wholev === "No" || !["Vans", "HGV (all diesel)", "HGV refrigerated (all diesel)"].includes(data.category)) {
        //convert wrapper of km/miles to tonne.miles/tonne.km
        uom = "tonne.km";
        distance = data.mass * data.distance;
        //checking mass_unit as well.
        if (data["mass_unit"] === "kg") distance /= 1000;
        if (data.uom === "miles") distance *= 1.60934;
      } else {
        distance = data.distance;
      }
      const filterBy = {
        "Level 1": "Freighting goods",
        "Level 2": data.category,
        "Level 3": data.type,
        "Level 4": data.level4,
        "Column Text": data.columntext,
        UOM: uom,
      };

      const rows = filterFactors(filterBy, year);
      const conversion_factor = rows[0];
      const result = (conversion_factor * distance) / 1000;
      const code = filterFactors(filterBy, "ID")[0];
      //CALCULATING WTT
      const wttFilterBy = {
        "Level 1": "WTT- delivery vehs & freight",
        "Level 2": `WTT- ${data.category}`,
        "Level 3": data.type,
        "Level 4": data.level4,
        "Column Text": data.columntext,
        UOM: uom,
      };

      const wttcode = filterFactors(wttFilterBy, "ID")[0];
      const wtt = (filterFactors(wttFilterBy, year)[0] * distance) / 1000;
      // KWhours
      const kwhcode = `6_${code?.split("_").slice(1, -1).join("_")}_5`;
      const kwh_net_cv = filterFactors(
        {
          "Level 2": data.category,
          "Level 3": data.type,
          "Column Text": data.columntext,
          UOM: uom,
          "GHG/Unit": "kWh (Net CV)",
        },
        year
      )[0];
      let kwh = (distance * kwh_net_cv).toFixed(2);
      //CALCULATING SCOPE
      const scope = data.ownership === this.OWNERSHIP_TYPES.Outsourced ? 3 : 1;
      //CALCULATING GHG
      let ghg, ghgwtt;

      if (data.ownership === this.OWNERSHIP_TYPES.Owned) {
        // delete upstream as well
        delete data.streamtype;
        ghg = getGHGCode(3); // Company owned vehicle
        ghgwtt = getGHGCode(8); // 3.3 Fuel
      } else if (data.streamtype === this.OUTSOURCED_STREAMS.Upstream) {
        ghg = ghgwtt = getGHGCode(9); // 3.4 Upstream for both
      } else {
        ghg = ghgwtt = getGHGCode(14); // 3.9 Downstream for both
      }
      //RETURNING DATA
      let obj = {
        ...data,
        result,
        scope,
        ghg,
        ghgwtt,
        wtt,
        wttcode,
        code,
      };
      if (data.ownership === this.OWNERSHIP_TYPES.Owned && !isNaN(kwh)) {
        obj = { ...obj, kwhcode, kwh };
      }
      console.log(obj);

      return obj;
    },

    component: Frieghting,
    bg: require("./logos/Freight.png"),
    OWNERSHIP_TYPES: { Owned: "Owned", Outsourced: "Outsourced" },
    OUTSOURCED_STREAMS: { Upstream: "Upstream", Downstream: "Downstream" },
  },
  {
    title: "Waste",
    name: "waste",
    dataFields: () => ["amount"],
    hasWTT: false,
    fields: [],
    component: Waste,
    fields: ["date", "category", "type", "columntext", "amount", "uom"],
    fieldTitles: {
      date: "Date of Reading",
      category: "Waste Type",
      type: "Waste Sub-Type",
      columntext: "Disposal Method",
      uom: "UOM",
      amount: "Weight",
    },
    filterer: (data, fuzzyChecking = false) => {
      const filterBy = {
        "Level 1": "Waste disposal",
        "Level 2": data.category,
        "Level 3": data.type,
        "Column Text": data.columntext,
      };
      fuzzyChecking = false;
      try {
        if (fuzzyChecking) {
          const updatedFilterBy = fuzzyPropsReplace(filterBy);
          //changing data
          Object.keys(att_excel_vs_forms).forEach((att) => {
            if (att in updatedFilterBy) {
              data[att_excel_vs_forms[att]] = updatedFilterBy[att];
            }
          });
          return updatedFilterBy;
        } else {
          return filterBy;
        }
      } catch (err) {
        return filterBy;
      }
    },
    pack: function (data, fuzzyChecking = false) {
      // CHECKING REQUIRED DATA
      commonChecks(data, this.fields);
      // CALCULATING RESULT
      let year = extractYear(data);
      //         not including uom while filtering, because there is one available option: tonnes in the database. We need another: kg
      fuzzyChecking = false;
      const filterBy = this.filterer(data, fuzzyChecking);
      const rows = filterFactors(filterBy, year);
      const conversion_factor = rows[0];
      const code = filterFactors(filterBy, "ID")[0];
      let result = (conversion_factor * data.amount) / 1000;
      if (data.uom === "kg") {
        result /= 1000;
      } else if (data.uom === "g") {
        result /= 1000 * 1000;
      }
      // CALCUALTING GHG
      const ghg = getGHGCode(10);
      const scope = 3;
      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }

      return {
        ...data,
        code,
        result,
        ghg,
        // ghgwtt,
        scope,
        code,
      };
    },
    bg: require("./logos/Waste.png"),
  },
  {
    title: "Bulk Materials",
    name: "bulk_materials",
    dataFields: () => ["amount"],

    id_range: () => ids.bulk_materials,
    hasWTT: false,
    component: BulkMaterials,
    fields: ["date", "category", "type", "columntext", "amount", "uom", "q1"],
    filterer: (data, fuzzyChecking = false) => {
      const filterBy = {
        "Level 2": data.category,
        "Level 3": data.type,
        "Column Text": data.columntext,
      };
      try {
        if (fuzzyChecking) {
          const updatedFilterBy = fuzzyPropsReplace(filterBy);
          //changing data
          Object.keys(att_excel_vs_forms).forEach((att) => {
            if (att in updatedFilterBy) {
              data[att_excel_vs_forms[att]] = updatedFilterBy[att];
            }
          });
          return updatedFilterBy;
        } else {
          return filterBy;
        }
      } catch (err) {
        return filterBy;
      }
    },
    pack: function (data, fuzzyChecking = false) {
      // CHECKING REQUIRED DATA
      commonChecks(data, this.fields);
      // CALCULATING RESULT
      let year = extractYear(data);
      //         not including uom while filtering, because there is one available option: tonnes in the database. We need another: kg
      const filterBy = this.filterer(data, fuzzyChecking);
      const rows = filterFactors(filterBy, year, true, this.id_range());
      const conversion_factor = rows[0];
      const code = filterFactors(filterBy, "ID", true, this.id_range())[0];
      let result = (conversion_factor * data.amount) / 1000;
      if (data.uom === "kg") {
        result /= 1000;
      } else if (data.uom === "g") {
        result /= 1000 * 1000;
      }
      // CALCUALTING GHG
      const ghg = getGHGCode(data.q1 === "Yes" ? 7 : 6);
      const scope = 3;

      return {
        ...data,
        code,
        result,
        ghg,
        scope,
        code,
      };
    },
    bg: require("./logos/Raw Materials.png"),
  },
  {
    title: "Flight",
    name: "flight",
    dataFields: () => ["distance"],
    fields: [
      "date",
      "q1",
      // "departure_airport",
      // "destination_airport",
      // "flight_class",
      "passengers",
      "return_ticket",
    ],
    excelFields: ["date", "q1", "departure_airport", "destination_airport", "flight_class", "passengers", "return_ticket"],
    pack: function (data) {
      // CHECKING REQUIRED
      const checkFields = [...this.fields];

      if (data.q1 === "Select Airports") {
        convertIATA(data); //Converting IATA Code to flight code
        checkFields.push("departure_airport");
        checkFields.push("destination_airport");
        checkFields.push("flight_class");
        if (!data.via_airport) delete data.via_airport;
      } else {
        delete data["departure_airport"];
        delete data["destination_airport"];
        checkFields.push("flight_type");
      }
      commonChecks(data, checkFields);

      let distance = data.distance;
      if (!distance) {
        const { totalDistance, flightType } = getFlightPropsBasedOnAirports(data);
        distance = totalDistance;
      }

      // CALCULATING RESULT
      let wtt = 0,
        result = 0;

      if (data.via_airport) {
        result =
          CalculateFlightResult(
            {
              ...data,
              destination_airport: data.via_airport,
              via_airport: undefined,
            },
            false
          ) +
          CalculateFlightResult(
            {
              ...data,
              departure_airport: data.via_airport,
              via_airport: undefined,
            },
            false
          );
        wtt =
          CalculateFlightResult(
            {
              ...data,
              destination_airport: data.via_airport,
              via_airport: undefined,
            },
            false,
            "wtt"
          ) +
          CalculateFlightResult(
            {
              ...data,
              departure_airport: data.via_airport,
              via_airport: undefined,
            },
            false,
            "wtt"
          );
        console.log(data, result, wtt);
      } else {
        result = CalculateFlightResult(data, false);
        wtt = CalculateFlightResult(data, false, "wtt");
      }

      const code = CalculateFlightResult(data, true);
      // CALCULATING WTT
      const wttcode = CalculateFlightResult(data, true, "wtt");
      // CALCULATING SCOPE
      const scope = 3;
      // CALCULATING GHG
      const ghg = getGHGCode(11);
      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }

      // RETURN RESUlT
      return {
        ...data,
        result,
        scope,
        wtt,
        ghg,
        ghgwtt,
        wtt,
        code,
        wttcode,
        distance,
      };
    },
    component: Flight,
    bg: require("./logos/Flight.png"),
  },
  {
    title: "Other Travels",
    name: "other_travels",
    dataFields: () => ["amount"],

    component: OtherTravels,
    fields: ["date", "category", "type", "amount", "uom"],
    fieldTitles: {
      date: "Date of Emissions",
      category: "Category",
      type: "Type",
      uom: "UOM",
      amount: "Distance (km)",
    },
    pack: function (data) {
      // CHECKING REQUIRED
      commonChecks(data, this.fields);

      // CALCULATING RESULT
      let year = extractYear(data);
      let UOM = "passenger." + data.uom?.replaceAll("passenger.", "");
      if (UOM === "passenger.mile") {
        UOM = "passenger.km";
      }
      const filterBy = {
        "Level 2": data.category,
        "Level 3": data.type,
        UOM,
      };

      const rows = filterFactors(filterBy, year);
      let conversion_factor = rows[0];
      if (data.uom === "passenger.mile" || data.uom?.includes("mile")) {
        conversion_factor *= 1.60934;
      }
      const result = (conversion_factor * data.amount * (data.passengers ?? 1)) / 1000;
      const code = filterFactors(filterBy, "ID")[0];
      // CALCULATING WTT
      const wttFilter = {
        "Level 1": data.category === "Ferry" ? "WTT- business travel- sea" : "WTT- pass vehs & travel- land",
        "Level 2": "WTT- " + data.category,
        "Level 3": data.type,
        UOM,
      };
      const wttcode = filterFactors(wttFilter, "ID")[0];

      conversion_factor = filterFactors(wttFilter, year)[0];
      if (data.uom === "passenger.mile" || data.uom?.includes("mile")) {
        conversion_factor *= 1.60934;
      }
      const wtt = (conversion_factor * data.amount) / 1000;
      const ghg = getGHGCode(11);
      // CALCULATING SCOPE
      const scope = 3;
      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }
      return {
        ...data,
        code,
        result,
        ghg,
        ghgwtt,
        scope,
        wtt,
        wttcode,
      };
    },
    bg: require("./logos/Other Travel.png"),
  },
  {
    title: "Commuting",
    name: "commuting",
    dataFields: (data) => (data["q1"] === "Monthly Distance" ? ["amount"] : ["days"]),
    methodNames: ["Monthly Distance", "One Way Distance"],
    component: Commuting,
    fields: ["date", "category", "type", "uom"],
    pack: function (data) {
      // CHECKING REQUIRED
      commonChecks(data, this.fields);
      // CALCULATING RESULT
      if (!data.passengers) {
        data.passengers = 1;
      }
      let year = extractYear(data);
      let UOM = data.uom;
      if (["Ferry", "Bus", "Taxis", "Rail"].includes(data?.category)) {
        UOM = "passenger." + UOM;
      }
      if (UOM === "passenger.mile") {
        UOM = "passenger.km";
      }
      const filterBy = {
        form: "commuting",
        "Level 2": data.category,
        "Level 3": data.type,
        UOM,
      };
      if (data.columntext) filterBy["Column Text"] = data.columntext;
      const rows = filterFactors(filterBy, year);
      let conversion_factor = rows[0];
      if (data.uom === "passenger.mile" || data.uom?.includes("mile")) {
        if (["Ferry", "Taxis", "Bus", "Rail"].includes(data.category)) conversion_factor *= 1.60934;
      }
      const amount = data.q1 === this.methodNames[0] ? data.amount : data.amount2 * data.days * 2; // dan advice, 09/02/2024
      const passengers = data.category == "Rail" || data.category == "Bus" ? data.passengers : 1;
      const result = (conversion_factor * amount * passengers) / 1000;
      const code = filterFactors(filterBy, "ID")[0];
      // CALCULATING WTT
      const wttFilter = {
        "Level 2": "WTT- " + data.category,
        "Level 3": data.type,
        UOM,
      };
      if (data.columntext) wttFilter["Column Text"] = data.columntext;
      const wttcode = filterFactors(wttFilter, "ID")[0];
      conversion_factor = filterFactors(wttFilter, year)[0]; // now it's the wtt conversion factor
      if (data.uom === "passenger.mile" || data.uom?.includes("mile")) {
        if (["Ferry", "Taxis", "Bus", "Rail"].includes(data.category)) conversion_factor *= 1.60934;
      }

      const wtt = (conversion_factor * amount * passengers) / 1000;
      const ghg = getGHGCode(12);
      // CALCULATING SCOPE
      const scope = 3;
      // CALCULATING GHG WTT
      let ghgwtt = getGHGCode(12);
      return {
        ...data,
        code,
        result,
        ghg,
        ghgwtt,
        scope,
        wtt,
        wttcode,
      };
    },
    bg: require("./logos/Commute.png"),
  },
  {
    title: "Hotel Stay",
    name: "hotel",
    dataFields: () => ["guests"],
    amount_key: "guests",
    fields: ["date", "country", "guests"],
    id_range: () => Array.from({ length: 55 }, (_, i) => `29_600_${4000 + i}_13_1`),
    pack: function (data) {
      commonChecks(data, this.fields);
      let year = extractYear(data);
      const code = filterFactors({ "Level 3": data.country }, "ID", true, this.id_range())[0];
      let emissions;
      while (year >= 2021) {
        emissions = filterFactors({ "Level 3": data.country }, year, true, this.id_range)[0];
        if (emissions) break;
        year--;
      }
      const result = (data.guests * emissions) / 1000;
      const obj = {
        ...data,
        code,
        scope: 3,
        ghg: getGHGCode(11),
        result,
      };
      return obj;
    },
    component: Hotel,
    hasWTT: false,
    bg: require("./logos/Hotel Stays.png"),
  },
  {
    title: "Refrigerator",
    hasWTT: false,
    name: "refrigerator",
    dataFields: () => ["amount"],
    fields: ["date", "type", "amount"],
    fieldTitles: {
      date: "Date of Refill/ Topup",
      type: "Type",
      amount: "Amount",
    },
    // msgs: ["Unit of measure is KG."],
    pack: function (data) {
      // CHECKING REQUIRED DATA
      commonChecks(data, this.fields);

      // CALCULATING RESULT
      let year = extractYear(data);
      const category = filterFactors({ "Level 1": "Refrigerant & other", "Level 3": data.type }, "Level 2")[0];
      const filterBy = { "Level 3": data.type, UOM: "kg" };
      const rows = filterFactors(filterBy, year);
      const conversion_factor = rows[0];
      // CALCULATING GHG
      const ghg = getGHGCode(4);
      // CALCULATING RESULT
      const result = (conversion_factor * data.amount) / 1000;
      const code = filterFactors(filterBy, "ID")[0];
      // CALCULATING SCOPE
      const scope = 1;
      // CALCULATING GHG WTT
      let ghgwtt;
      if (scope === 3) {
        ghgwtt = ghg;
      } else {
        ghgwtt = getGHGCode(8);
      }
      // RETURNING RESULT
      return {
        ...data,
        uom: "kg",
        code,
        ghg,
        category,
        scope,
        result,
      };
    },
    component: Refrigerator,
    bg: require("./logos/Refrigerator.png"),
  },
  {
    title: "Water",
    name: "water",
    dataFields: () => ["amount"],
    fields: ["date", "amount", "waste%", "uom"],
    id_range: () => ["17_404_4005_1_1", "17_404_4005_10_1"],
    pack: function (data) {
      if (data["waste%"] === null || data["waste%"] === undefined) data["waste%"] = 100;
      commonChecks(data, this.fields);
      let year = extractYear(data);
      const code = filterFactors({ UOM: data.uom }, "ID", true, this.id_range())[0];

      const supply_factor = filterFactors({ ID: code }, year)[0];
      const waste_factor = filterFactors(
        {
          ID: code === "17_404_4005_1_1" ? "18_405_4006_1_1" : "18_405_4006_10_1",
        },
        year
      )[0];

      const supply_result = (supply_factor * data.amount) / 1000;
      const waste_result = (waste_factor * data.amount * data["waste%"]) / (1000 * 100);

      const result = supply_result + waste_result;
      const obj = {
        ...data,
        code,
        scope: 3,
        supply_result,
        waste_result,
        supply_ghg: getGHGCode(6),
        waste_ghg: getGHGCode(10),
        result,
      };
      return obj;
    },
    component: Water,
    hasWTT: false,
    bg: require("./logos/Water.png"),
  },
  {
    title: "Ingredients",
    name: "ingredients",
    dataFields: () => ["amount"],
    fields: ["category", "type", "columntext", "amount", "uom"],
    hasWTT: false,
    pack: function (data) {
      commonChecks(data, this.fields);
      // note: uom is g, kg, and tonne while the default is kg
      ["factor", "production_system", "source_db_id", "origin_region"].forEach(
        (key) => (data[key] = filterFactorsIngredients({ columntext: data?.columntext, type: data?.type, category: data?.category }, key)[0])
      );
      console.log(data);
      //ghg?
      let mul = 1;
      if (data.uom === "g") {
        mul = 1 / 1000;
      } else if (data.uom === "tonne") {
        mul = 1000;
      }
      return {
        ...data,
        ghg: 6,
        result: (data.factor * data.amount * mul) / 1000,
      };
    },
    component: Ingredients,
    bg: require("./logos/Ingredients.png"),
  },
  {
    title: "Paper",
    name: "paper",
    dataFields: () => ["amount"],
    hasWTT: false,
    component: Paper,
    fields: ["date", "amount", "uom", "columntext", "size", "gsm"],
    uoms: ["reams", "sheets"],
    pack: function (data) {
      commonChecks(data, this.fields);
      let year = extractYear(data);
      const gsp = paper_weights[data.gsm][data.size];
      const pages = data.amount * (data.uom === this.uoms[1] ? 1 : 500);
      const weight = (pages * gsp) / 1000;
      const factors = filterFactors({ form: "paper", "Column Text": data.columntext }, year);
      const id = filterFactors({ form: "paper", "Column Text": data.columntext }, "ID");
      const result = (weight * factors[0]) / (1000 * 1000); // tonne conversion
      return { ...data, gsp, pages, weight, ghg: getGHGCode(6), result };
    },
    bg: require("./logos/Paper.png"),
  },
  {
    title: "Home Workers",
    hasWTT: false,
    name: "home_workers",
    dataFields: (data, isSpread = false) => (!isSpread ? ["workers"] : ["weeks_per_year"]),

    component: HomeWorkers,
    fields: ["date", "occupancy", "workers", "hours_per_day", "days_per_week", "weeks_per_year"],
    pack: function (data) {
      commonChecks(data, this.fields);
      const ids = {
        "Office Equipment": "32_601_9999_12_1",
        "Homeworking (office equipment + heating)": "32_603_9999_12_1",
      };
      let year = extractYear(data);
      let result = (data.workers * data.hours_per_day * data.days_per_week * data.weeks_per_year) / 1000;
      const ID = data.occupancy.toLowerCase().includes("single") ? ids["Homeworking (office equipment + heating)"] : ids["Office Equipment"];
      result *= filterFactors({ ID }, year)[0];
      const ghg = getGHGCode(12);
      return { ...data, result, ghg, code: ID };
    },
    bg: require("./logos/Home.png"),
  },
  {
    title: "Spendings",
    name: "spending",
    amount_key: "spend",
    dataFields: () => ["spend"],
    fields: ["format", "date", "product", "q1", "spend", "currency"],
    pack: async function (data) {
      commonChecks(data, this.fields);
      console.log(data.format === "SIC (UK)" ? data.sic_uk : "EPA");
      const factor = getFactors(data.format === "SIC (UK)" ? data.sic_uk : "EPA").find((f) => f.product === data.product).factor;
      const currencyCode = this.findCurrency(data.currency).name;
      const currency_conversion = await convertCurrency(data.date, currencyCode, data.format === "SIC (UK)" ? "GBP" : "USD");
      return {
        ...data,
        currency_conversion,
        currencyCode,
        c_am: currency_conversion * data.spend, //converted amount
        result: (currency_conversion * data.spend * factor) / 1000,
        ghg: data.q1 === "Yes" ? 7 : 6,
      };
    },
    component: Spending,
    bg: require("./logos/CoinStack.png"),
    hasWTT: false,
    findCurrency: (val) => currencies.find((x) => val?.includes(x.name)),
  },
  {
    title: "Product",
    name: "product",
    dataFields: () => ["units"],
    fields: ["date", "product_name", "uom", "units", "q1", "q2"],
    em_fields: ["em_emb", "em_dist", "em_manf", "em_dist_cust", "em_use", "em_waste"],
    pack: function (data) {
      removePropsFromObject(data);
      commonChecks(data, [...this.fields, ...this.em_fields], false);
      /**
       * Individual results will be saved in [key]_result
       */
      let result = 0;
      this.em_fields.forEach((prop) => {
        let value = data[prop];
        if (data.uom === "kgCO2e") {
          value /= 1000;
        }
        data[prop + "_result"] = value * data.units;
        result += data[prop + "_result"];
      });

      // if (data.uom?.includes("kg")) result /= 1000;
      return {
        ...data,
        result,
        emb_ghg: getGHGCode(6),
        dist_ghg: getGHGCode(data.q1 === "Yes" ? 9 : 14),
        manf_ghg: getGHGCode(15),
        dist_cust_ghg: getGHGCode(data.q2 === "Yes" ? 9 : 14),
        use_ghg: getGHGCode(16),
        waste_ghg: getGHGCode(17),
      };
    },
    component: Product,
    bg: require("./logos/product.png"),
  },
  {
    title: "Add",
    name: "additional",
    dataFields: () => ["result", "wtt"],
    amount_key: "result",
    fields: ["date", "result", "q1", "ghg"],
    pack: function (updatedData) {
      // const updatedData = { ...updatedData };
      commonChecks(updatedData, this.fields);
      // converting ghg to its code
      if (typeof updatedData.ghg !== "number") {
        updatedData.ghg = findGHG(updatedData.ghg)?.code;
        // if scopw 1 or 2 make the wtt scoped 3.3 fuel and other emissions else if scope3 use the same ghg as emissions
        updatedData.ghgwtt = [1, 2, 3, 4, 5].includes(updatedData.ghg) ? 8 : updatedData.ghg;
      }
      if (updatedData.q1 === "Yes") {
        if (!updatedData.wtt) {
          throw "wtt required";
        }
      } else {
        delete updatedData.wtt;
        delete updatedData.ghgwtt;
      }
      console.log(updatedData);

      return { ...updatedData };
    },
    component: Additional,
    bg: require("./logos/Additional.png"),
  },
];
const dataFormsComplete = JSON.parse(JSON.stringify(dataForms));
export { dataFormsComplete };
export const getForms = () => {
  return dataForms.filter((form) => {
    const maxInputsAllowed = planValue("form_" + form.name);
    return maxInputsAllowed == 0 ? false : true;
  });
};
export const updateDataForms = (forms) => {
  if (!forms) return;
  dataForms = dataForms.map((dataForm) => ({
    ...dataForm,
    ...forms[dataForm.name],
  }));
};

export const filterForms = (names) => {
  try {
    dataForms = dataForms.filter((form) => names.includes(form.name));
  } catch (err) {
    alert(names);
  }
};

export const findForm = (name) => {
  const form = dataForms.find((e) => e.name === name);
  return form;
};

export const tableFields = (name) => {
  let form = findForm(name);
  return form.texts.filter((text) => text["s"]).map((text) => text.name);
};
export const fieldTitle = (name, field, type = "forms") => {
  try {
    const form = findForm(name);
    const title = store.getState().config[type].texts.find((text) => text.name === field).title;
    if (title) return title;
    throw "oh no!";
  } catch {
    return field;
  }
};
export const dataFormsTitles = dataForms.map((x) => x.title);
export const formNameByTitle = (x) => {
  for (let form of dataForms) {
    if (form.title.toLowerCase().includes(x.toLowerCase()) || x.toLowerCase().includes(form.title.toLowerCase())) {
      return form.name;
    }
  }
  return "err";
};
export const fixedFormFields = {
  tnd: "T&D",
  wtt: "WTT",
  wtt_tnd: "WTT T&D",
  uom: "Unit of Measure",
  category: "Category",
  mass_unit: "Mass Unit",
  ghg: "GHG Category",
  scope: "Scope",
  streamtype: "Upstream/ Downstream",
  ownership: "Owner Ship",
  tndghg: "T&D GHG Category",
  ghgwtt: "WTT GHG Category",
};

export const fixedDataFields = ["cost"];
